import React from "react";
import CustomSolution from "../components/customSolution";
import PageTitle from "../components/pageTitle";
import UnlimitedNotice from "../components/unlimitednotice";

function HomePage() {
  return (
    <React.Fragment>
      <PageTitle
        title={
          "Hosting Gujju | Made in India | Helps you to create your internet brand"
        }
      />
      {/* <Notification
        notice={
          "Introducing block storage. Attach up to 12 TB of SSD-based storage to your cloud server. "
        }
        link={""}
      /> */}
      <section id="content">
        {/* <section className="content-row content-row-clouds content-row-full-height">
          <div className="container">
            <header className="content-header content-header-large content-header-uppercase">
              <h1>
                Find Your Internet <mark>Name</mark>
              </h1>
              <p>
                Every great project deserves the perfect domain name. Choose
                from{" "}
                <span className="text-color-secondary">
                  hundreds of extensions
                </span>{" "}
                to get started.
              </p>
            </header>
            <div className="column-row align-center">
              <div className="column-66">
                <form
                  className="form-full-width"
                  method="get"
                  action="clients/cart.php"
                >
                  <div className="group align-center form-row">
                    <input type="hidden" name="a" value="add" />
                    <input type="hidden" name="domain" value="register" />
                    <input
                      className="group-stretch"
                      type="text"
                      name="query"
                      placeholder="Domain..."
                      required
                    />
                    <button className="button-secondary">
                      <i className="fas fa-search icon-left"></i>Search
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="column-row align-center">
              <div className="column-50">
                <p className="text-align-center">
                  <small className="text-color-gray">
                    Hosting Gujju is offering fully automated domain purchases.
                  </small>
                </p>
              </div>
            </div>
          </div>
          <div className="content-background content-background-light">
            <img src="uploads/banner-domain-search.jpg" alt="" />
          </div>
        </section> */}
        <section
          id="pricing"
          className="content-row content-row-dark content-row-full-height"
        >
          <div className="container">
            <header className="content-header content-header-large content-header-uppercase">
              <h1>
                <mark>Unlimited</mark> Web Hosting
              </h1>
              <p>
                Fully managed enterprise hosting with failover support and load
                balancing, powered by hosting industry leader{" "}
                <span className="text-color-secondary">
                  cPanel<sup>&reg;</sup>
                </span>{" "}
                control panel.
              </p>
            </header>
            <div className="tab-group tab-group-switch-style">
              <div className="tab-item" data-title="3 Year">
                <div className="column-row align-center-bottom">
                  <div className="column-33">
                    <div className="product-box">
                      <div className="product-header">
                        <h4>Starter</h4>
                        <p>Buy &amp; Renew at same cost!</p>
                      </div>
                      <div className="product-price">
                        ₹40<span className="term">/ month</span>
                      </div>
                      <div className="product-features">
                        <ul>
                          <li>
                            <strong>1</strong> Hosted Domains
                          </li>
                          <li>
                            <strong>1 GB</strong> SSD Storage
                          </li>
                          <li>
                            <strong>1</strong> MySQL Database
                          </li>
                          <li>
                            <strong>Unlimited</strong> Subdomains
                          </li>
                          <li>
                            <strong>Unlimited</strong> Bandwidth
                          </li>
                        </ul>
                        <ul>
                          <li>Powered by cPanel&reg;</li>
                          <li>Free SSL Certificate</li>
                          <li>WordPress Optimized</li>
                          <li>99.95% Uptime SLA</li>
                        </ul>
                      </div>
                      <div className="product-order">
                        <a
                          className="button button-secondary"
                          href="https://hostinggujju.com/clients/cart.php?a=add&pid=1"
                        >
                          <i className="fas fa-shopping-cart icon-left"></i>
                          Order Now
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="column-33">
                    <div className="product-box product-box-popular">
                      <div className="product-popular">Most Popular</div>
                      <div className="product-header">
                        <h4>Value</h4>
                        <p>Buy &amp; Renew at same cost!</p>
                      </div>
                      <div className="product-price">
                        ₹100<span className="term">/ month</span>
                      </div>
                      <div className="product-features">
                        <ul>
                          <li>
                            <strong>1</strong> Hosted Domains
                          </li>
                          <li>
                            <strong>Unlimited GB</strong> SSD Storage
                          </li>
                          <li>
                            <strong>Unlimited</strong> MySQL Database
                          </li>
                          <li>
                            <strong>Unlimited</strong> Subdomains
                          </li>
                          <li>
                            <strong>Unlimited</strong> Bandwidth
                          </li>
                        </ul>
                        <ul>
                          <li>Powered by cPanel&reg;</li>
                          <li>Free SSL Certificate</li>
                          <li>WordPress Optimized</li>
                          <li>99.95% Uptime SLA</li>
                        </ul>
                      </div>
                      <div className="product-order">
                        <a
                          className="button button-secondary"
                          href="https://hostinggujju.com/clients/cart.php?a=add&pid=2"
                        >
                          <i className="fas fa-shopping-cart icon-left"></i>
                          Order Now
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="column-33">
                    <div className="product-box">
                      <div className="product-header">
                        <h4>Delux</h4>
                        <p>Buy &amp; Renew at same cost!</p>
                      </div>
                      <div className="product-price">
                        ₹150<span className="term">/ month</span>
                      </div>
                      <div className="product-features">
                        <ul>
                          <li>
                            <strong>Unlimited</strong> Hosted Domains
                          </li>
                          <li>
                            <strong>Unlimited GB</strong> SSD Storage
                          </li>
                          <li>
                            <strong>Unlimited</strong> MySQL Database
                          </li>
                          <li>
                            <strong>Unlimited</strong> Subdomains
                          </li>
                          <li>
                            <strong>Unlimited</strong> Bandwidth
                          </li>
                        </ul>
                        <ul>
                          <li>Powered by cPanel&reg;</li>
                          <li>Free SSL Certificate</li>
                          <li>WordPress Optimized</li>
                          <li>99.95% Uptime SLA</li>
                        </ul>
                      </div>
                      <div className="product-order">
                        <a
                          className="button button-secondary"
                          href="https://hostinggujju.com/clients/cart.php?a=add&pid=3"
                        >
                          <i className="fas fa-shopping-cart icon-left"></i>
                          Order Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-item" data-title="1 Year">
                <div className="column-row align-center-bottom">
                  <div className="column-33">
                    <div className="product-box">
                      <div className="product-header">
                        <h4>Starter</h4>
                        <p>Buy &amp; Renew at same cost!</p>
                      </div>
                      <div className="product-price">
                        ₹60<span className="term">/ month</span>
                      </div>
                      <div className="product-features">
                        <ul>
                          <li>
                            <strong>1</strong> Hosted Domains
                          </li>
                          <li>
                            <strong>1 GB</strong> SSD Storage
                          </li>
                          <li>
                            <strong>1</strong> MySQL Database
                          </li>
                          <li>
                            <strong>Unlimited</strong> Subdomains
                          </li>
                          <li>
                            <strong>Unlimited</strong> Bandwidth
                          </li>
                        </ul>
                        <ul>
                          <li>Powered by cPanel&reg;</li>
                          <li>Free SSL Certificate</li>
                          <li>WordPress Optimized</li>
                          <li>99.95% Uptime SLA</li>
                        </ul>
                      </div>
                      <div className="product-order">
                        <a
                          className="button button-secondary"
                          href="https://hostinggujju.com/clients/cart.php?a=add&pid=1"
                        >
                          <i className="fas fa-shopping-cart icon-left"></i>
                          Order Now
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="column-33">
                    <div className="product-box product-box-popular">
                      <div className="product-popular">Most Popular</div>
                      <div className="product-header">
                        <h4>Value</h4>
                        <p>Buy &amp; Renew at same cost!</p>
                      </div>
                      <div className="product-price">
                        ₹120<span className="term">/ month</span>
                      </div>
                      <div className="product-features">
                        <ul>
                          <li>
                            <strong>1</strong> Hosted Domains
                          </li>
                          <li>
                            <strong>Unlimited GB</strong> SSD Storage
                          </li>
                          <li>
                            <strong>Unlimited</strong> MySQL Database
                          </li>
                          <li>
                            <strong>Unlimited</strong> Subdomains
                          </li>
                          <li>
                            <strong>Unlimited</strong> Bandwidth
                          </li>
                        </ul>
                        <ul>
                          <li>Powered by cPanel&reg;</li>
                          <li>Free SSL Certificate</li>
                          <li>WordPress Optimized</li>
                          <li>99.95% Uptime SLA</li>
                        </ul>
                      </div>
                      <div className="product-order">
                        <a
                          className="button button-secondary"
                          href="https://hostinggujju.com/clients/cart.php?a=add&pid=2"
                        >
                          <i className="fas fa-shopping-cart icon-left"></i>
                          Order Now
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="column-33">
                    <div className="product-box">
                      <div className="product-header">
                        <h4>Delux</h4>
                        <p>Buy &amp; Renew at same cost!</p>
                      </div>
                      <div className="product-price">
                        ₹170<span className="term">/ month</span>
                      </div>
                      <div className="product-features">
                        <ul>
                          <li>
                            <strong>Unlimited</strong> Hosted Domains
                          </li>
                          <li>
                            <strong>Unlimited GB</strong> SSD Storage
                          </li>
                          <li>
                            <strong>Unlimited</strong> MySQL Database
                          </li>
                          <li>
                            <strong>Unlimited</strong> Subdomains
                          </li>
                          <li>
                            <strong>Unlimited</strong> Bandwidth
                          </li>
                        </ul>
                        <ul>
                          <li>Powered by cPanel&reg;</li>
                          <li>Free SSL Certificate</li>
                          <li>WordPress Optimized</li>
                          <li>99.95% Uptime SLA</li>
                        </ul>
                      </div>
                      <div className="product-order">
                        <a
                          className="button button-secondary"
                          href="https://hostinggujju.com/clients/cart.php?a=add&pid=3"
                        >
                          <i className="fas fa-shopping-cart icon-left"></i>
                          Order Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-item" data-title="Monthly">
                <div className="column-row align-center-bottom">
                  <div className="column-33">
                    <div className="product-box">
                      <div className="product-header">
                        <h4>Starter</h4>
                        <p>Buy &amp; Renew at same cost!</p>
                      </div>
                      <div className="product-price">
                        ₹100<span className="term">/ month</span>
                      </div>
                      <div className="product-features">
                        <ul>
                          <li>
                            <strong>1</strong> Hosted Domains
                          </li>
                          <li>
                            <strong>1 GB</strong> SSD Storage
                          </li>
                          <li>
                            <strong>1</strong> MySQL Database
                          </li>
                          <li>
                            <strong>Unlimited</strong> Subdomains
                          </li>
                          <li>
                            <strong>Unlimited</strong> Bandwidth
                          </li>
                        </ul>
                        <ul>
                          <li>Powered by cPanel&reg;</li>
                          <li>Free SSL Certificate</li>
                          <li>WordPress Optimized</li>
                          <li>99.95% Uptime SLA</li>
                        </ul>
                      </div>
                      <div className="product-order">
                        <a
                          className="button button-secondary"
                          href="https://hostinggujju.com/clients/cart.php?a=add&pid=1"
                        >
                          <i className="fas fa-shopping-cart icon-left"></i>
                          Order Now
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="column-33">
                    <div className="product-box product-box-popular">
                      <div className="product-popular">Most Popular</div>
                      <div className="product-header">
                        <h4>Value</h4>
                        <p>Buy &amp; Renew at same cost!</p>
                      </div>
                      <div className="product-price">
                        ₹150<span className="term">/ month</span>
                      </div>
                      <div className="product-features">
                        <ul>
                          <li>
                            <strong>1</strong> Hosted Domains
                          </li>
                          <li>
                            <strong>Unlimited GB</strong> SSD Storage
                          </li>
                          <li>
                            <strong>Unlimited</strong> MySQL Database
                          </li>
                          <li>
                            <strong>Unlimited</strong> Subdomains
                          </li>
                          <li>
                            <strong>Unlimited</strong> Bandwidth
                          </li>
                        </ul>
                        <ul>
                          <li>Powered by cPanel&reg;</li>
                          <li>Free SSL Certificate</li>
                          <li>WordPress Optimized</li>
                          <li>99.95% Uptime SLA</li>
                        </ul>
                      </div>
                      <div className="product-order">
                        <a
                          className="button button-secondary"
                          href="https://hostinggujju.com/clients/cart.php?a=add&pid=2"
                        >
                          <i className="fas fa-shopping-cart icon-left"></i>
                          Order Now
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="column-33">
                    <div className="product-box">
                      <div className="product-header">
                        <h4>Delux</h4>
                        <p>Buy &amp; Renew at same cost!</p>
                      </div>
                      <div className="product-price">
                        ₹200<span className="term">/ month</span>
                      </div>
                      <div className="product-features">
                        <ul>
                          <li>
                            <strong>Unlimited</strong> Hosted Domains
                          </li>
                          <li>
                            <strong>Unlimited GB</strong> SSD Storage
                          </li>
                          <li>
                            <strong>Unlimited</strong> MySQL Database
                          </li>
                          <li>
                            <strong>Unlimited</strong> Subdomains
                          </li>
                          <li>
                            <strong>Unlimited</strong> Bandwidth
                          </li>
                        </ul>
                        <ul>
                          <li>Powered by cPanel&reg;</li>
                          <li>Free SSL Certificate</li>
                          <li>WordPress Optimized</li>
                          <li>99.95% Uptime SLA</li>
                        </ul>
                      </div>
                      <div className="product-order">
                        <a
                          className="button button-secondary"
                          href="https://hostinggujju.com/clients/cart.php?a=add&pid=3"
                        >
                          <i className="fas fa-shopping-cart icon-left"></i>
                          Order Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <UnlimitedNotice />
          </div>
        </section>
        <section className="content-row content-row-gray">
          <div className="container">
            <header className="content-header">
              <h2>Enterprise Cloud Platform</h2>
              <p>
                Deploy your service infrastructure on our fully redundant, high
                performance cloud platform and benefit from its high
                reliability, security and enterprise feature set.
              </p>
            </header>
            <div className="column-row align-center-bottom text-align-center">
              <div className="column-33">
                <i className="fas fa-rocket icon-feature"></i>
                <h3>High Performance</h3>
                <p>
                  We operate one of the most advanced 100 Gbit networks in the
                  world, complete with Anycast support and extensive DDoS
                  protection.
                </p>
              </div>
              <div className="column-33">
                <i className="fas fa-cloud icon-feature"></i>
                <h3>Fully Redundant</h3>
                <p>
                  Our cloud platform offers a 99.99% SLA uptime guarantee with
                  full hardware and network redundancy to keep your services
                  online.
                </p>
              </div>
              <div className="column-33">
                <i className="fas fa-shield-alt icon-feature"></i>
                <h3>Secure Infrastructure</h3>
                <p>
                  All datacenters are Tier 4 certified and provide advanced fire
                  and intrusion protection combined with enterprise networking
                  hardware.
                </p>
              </div>
            </div>
            <div className="column-row align-center-bottom text-align-center">
              <div className="column-33">
                <i className="fas fa-sync-alt icon-feature"></i>
                <h3>Instant Activation</h3>
                <p>
                  Your ordered product will be automatically and instantly
                  activated as long as you are using a verified PayPal account
                  for your purchase.
                </p>
              </div>
              <div className="column-33">
                <i className="fas fa-life-ring icon-feature"></i>
                <h3>Dedicated Support</h3>
                <p>
                  Our dedicated in-house support team is available 24/7 to
                  answer all technical difficulties you may encounter with any
                  of our products.
                </p>
              </div>
              <div className="column-33">
                <i className="fas fa-cog icon-feature"></i>
                <h3>Powerful Automation</h3>
                <p>
                  Our simple and intuitive developer API allows you to manage
                  and extend existing products, as well as submitting new orders
                  without interaction.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="content-row">
          <div className="container">
            <header className="content-header">
              <h2>What Our Customers Say</h2>
              <p>
                We've helped hundreds of clients with custom server solutions,
                enabling them to operate much more efficient and secure than
                they ever did before.
              </p>
            </header>
            <div className="column-row align-center-bottom">
              <div className="column-33">
                <div className="testimonial">
                  <p className="testimonial-content">
                    My customers didn't experience a single minute of downtime
                    since I moved my services over to Hosting Gujju.
                  </p>
                  <p className="testimonial-author">
                    Darshan Patel
                    <br />
                    <small>Intrasoft Technologies</small>
                  </p>
                </div>
              </div>

              <div className="column-33">
                <div className="testimonial">
                  <p className="testimonial-content">
                    Hosting Gujju helped me with a professional custom server
                    solution when my business was so rapidly growing my old
                    system couldn't handle the load anymore.
                  </p>
                  <p className="testimonial-author">
                    Saumya Kumari
                    <br />
                    <small>Kumari Chemical Industries</small>
                  </p>
                </div>
              </div>
              <div className="column-33">
                <div className="testimonial">
                  <p className="testimonial-content">
                    With affordable prices, Hosting Gujju is also one of the
                    most affordable when it comes to keeping your business’s
                    online presence live.
                  </p>
                  <p className="testimonial-author">
                    Shreya Patel
                    <br />
                    <small>Shree Hari Fashion</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <CustomSolution />
      </section>
    </React.Fragment>
  );
}

export default HomePage;
