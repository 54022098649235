import React from "react";

const Footer = () => {
  return (
    <footer id="footer">
      <section className="footer-primary">
        <div className="container">
          <div className="column-row">
            <div className="column-33">
              <h5>About Hosting Gujju</h5>
              <p>
                Our mission is to make life easier for website developers and
                their customers. We do it by offering easy to use, fast, and
                reliable web hosting services.
              </p>
            </div>
            <div className="column-66">
              <div className="column-row align-right-top">
                <div className="column-33">
                  <h5>Hosting</h5>
                  <ul>
                    <li>
                      <a href="/linux-shared-web-hosting">Linux Hosting</a>
                    </li>
                    <li>
                      <a href="/linux-wordpress-web-hosting">
                        Linux WordPress Hosting
                      </a>
                    </li>
                    <li>
                      <a href="/windows-shared-web-hosting">Windows Hosting</a>
                    </li>
                    <li>
                      <a href="/windows-wordpress-web-hosting">
                        Windows WordPress Hosting
                      </a>
                    </li>
                    <li>
                      <a href="/enterprise-linux-web-hosting">
                        Enterprise Hosting
                      </a>
                    </li>
                    <li>
                      <a href="/linux-reseller-hosting">
                        Linux Reseller Hosting
                      </a>
                    </li>
                    <li>
                      <a href="/windows-reseller-hosting">
                        Windows Reseller Hosting
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="column-25">
                  <h5>VPS</h5>
                  <ul>
                    <li>
                      <a href="/linux-vps">Linux VPS</a>
                    </li>
                    <li>
                      <a href="/windows-vps">Windows VPS</a>
                    </li>
                    <li>
                      <a href="/container-vps">Container VPS</a>
                    </li>
                    <li>
                      <a href="/storage-vps">Storage VPS</a>
                    </li>
                  </ul>
                </div>
                <div className="column-25">
                  <h5>Agreements</h5>
                  <ul>
                    <li>
                      <a href="/privacy-policy">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="/terms-of-service">Terms of Service</a>
                    </li>
                    <li>
                      <a href="/unlimited-hosting-policy">
                        Unlimited Hosting Policy
                      </a>
                    </li>
                    <li>
                      <a href="/acceptable-usage-policy">
                        Acceptable Usage Policy
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="column-25">
                  <h5>Quick Links</h5>
                  <ul>
                    <li>
                      <a href="/clients/clientarea.php">Login</a>
                    </li>
                    <li>
                      <a href="/clients/register.php">Register</a>
                    </li>
                    <li>
                      <a href="/clients/contact.php">Contact Us</a>
                    </li>
                    <li>
                      <a href="/clients/submitticket.php">Submit a Ticket</a>
                    </li>
                    <li>
                      <a href="/clients/serverstatus.php">Network</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="footer-secondary">
        <div className="container">
          <p>Copyright 2020 &copy; Hosting Gujju. All rights reserved.</p>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
