import React from "react";

const UnlimitedNotice = () => {
  return (
    <div className="column-row align-center">
      <div className="column-100 text-align-center">
        <p>
          <small className="text-color-gray">
            We don't limit the amount of storage and bandwidth your site can use
            as long as it complies with our{" "}
            <a href="/terms-of-service">Hosting Agreement</a>. Should your
            website bandwidth or storage usage present a risk to the stability,
            performance or uptime of our servers, we will notify you via email
            and you may be required to upgrade, or we may restrict the resources
            your website is using. It’s very rare that a website violates our
            Hosting Agreement and is typically only seen in sites that use
            hosting for file sharing or storage.
          </small>
        </p>
      </div>
    </div>
  );
};

export default UnlimitedNotice;
