import React from "react";

const CustomSolution = (props) => {
  return (
    <section className="content-row content-row-color content-row-clouds">
      <div className="container">
        <header className="content-header">
          <h2>Looking for a custom solution?</h2>
          <p>
            Our technicians can provide you with the best custom made solutions
            on the market, no matter whether you're a small business or large
            enterprise.
            <br />
            <br />
            <a
              className="button button-secondary"
              href="/clients/submitticket.php?step=2&deptid=1"
            >
              <i className="fas fa-envelope icon-left"></i>Get in touch
            </a>
          </p>
        </header>
      </div>
    </section>
  );
};

export default CustomSolution;
