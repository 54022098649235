import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { paths } from "./paths";
import HomePage from "../containers/homePage";
import MainLayout from "../layouts/main";
import LinuxSharedWebHosting from "../containers/linuxSharedWebHosting";
import LinuxWordPressWebHosting from "../containers/linuxWordPressWebHosting";
import WindowsSharedWebHosting from "../containers/windowsSharedWebHosting";
import WindowsWordPressWebHosting from "../containers/windowsWordPressWebHosting";
import EnterpriseWebHosting from "../containers/enterpriseWebHosting";
// import LinuxVps from "../containers/vps/linuxVps";
// import WindowsVps from "../containers/vps/windowsVps";
// import StorageVps from "../containers/vps/storageVps";
import LinuxResellerWebHosting from "../containers/linuxResellerWebHosting";
import PrivacyPolicy from "../containers/agreements/privacyPolicy";
import TermsOfService from "../containers/agreements/termsOfService";
import UnlimitedHostingPolicy from "../containers/agreements/unlimitedHostingPolicy";
import AcceptableUsagePolicy from "../containers/agreements/acceptableUsagePolicy";
// import containerVps from "../containers/vps/containerVps";

const MainRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <MainLayout>
        <Component {...props} />
      </MainLayout>
    )}
  />
);

const Routes = () => {
  return (
    <Switch>
      <MainRoute component={HomePage} exact path={paths.home} />
      {/* Hosting Routes */}
      <MainRoute
        component={LinuxSharedWebHosting}
        exact
        path={paths.linuxSharedWebHosting}
      />
      <MainRoute
        component={LinuxWordPressWebHosting}
        exact
        path={paths.linuxWordPressWebHosting}
      />
      <MainRoute
        component={LinuxResellerWebHosting}
        exact
        path={paths.linuxResellerWebHosting}
      />
      <MainRoute
        component={WindowsSharedWebHosting}
        exact
        path={paths.windowsSharedWebHosting}
      />
      <MainRoute
        component={WindowsWordPressWebHosting}
        exact
        path={paths.windowsWordPressWebHosting}
      />
      <MainRoute
        component={EnterpriseWebHosting}
        exact
        path={paths.enterpriseWebHosting}
      />
      <MainRoute component={PrivacyPolicy} exact path={paths.privacyPolicy} />
      <MainRoute component={TermsOfService} exact path={paths.termsOfService} />
      <MainRoute
        component={UnlimitedHostingPolicy}
        exact
        path={paths.unlimitedHostingPolicy}
      />
      <MainRoute
        component={AcceptableUsagePolicy}
        exact
        path={paths.acceptableUsagePolicy}
      />
      {/* VPS Routes */}
      {/* <MainRoute component={LinuxVps} exact path={paths.linuxVps} />
      <MainRoute component={WindowsVps} exact path={paths.windowsVps} />
      <MainRoute component={StorageVps} exact path={paths.storageVps} />
      <MainRoute component={containerVps} exact path={paths.containerVps} /> */}
      <Route path="*">
        <Redirect to="/" />
      </Route>

    </Switch>
  );
}

export default Routes;
