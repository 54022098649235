import React from "react";
import CustomSolution from "../../components/customSolution";
import PageTitle from "../../components/pageTitle";

function PrivacyPolicy() {
  return (
    <React.Fragment>
      <PageTitle title={"Privacy Policy | Hosting Gujju | Made in India"} />
      <section id="content">
        <section className="content-row content-row-clouds">
          <div className="container">
            <header className="content-header content-header-large content-header-uppercase">
              <h1>Privacy Policy</h1>
              <p>
                Your privacy is very important to us, below explains the
                information we collect from you, what we do with that
                information and our information security policy.
              </p>
            </header>
          </div>
        </section>
        <section className="content-row">
          <div className="container">
            <h2>Your Information</h2>
            <p>
              When you sign up for any of our services we will ask you to
              provide contact information for your convenience as well as legal
              reasons. This will include name, home address, phone number,
              e-mail addresses and payment details (credit or debit card and
              PayPal). If you need support our staff may need to know
              information to solve the problem such as operating system, browser
              software and editing software. When you visit this website we do
              record certain information using Google Analytics such as your
              location, browser version, ip address, screen resolution,
              operating system and flash support. This is so we can continue to
              provide the most convenient web site for all our visitors. Our
              website is protected by a Secure Sockets Layer (SSL) and verified
              by GeoTrust. This helps prevent eavesdropping on our website.
            </p>
            <hr />
            <h2>How Your Information Is Used</h2>
            <p>
              We will use your personal identifiable information in the
              following ways.
            </p>
            <ul>
              <li>
                For payment purposes and to provide convenient and targeted
                support.
              </li>
              <li>
                To contact you and announce special offers, general
                announcements and news. Please note you can opt out of receiving
                these emails at anytime by following the instructions at the
                bottom of the e-mail.
              </li>
              <li>
                To improve our service and the marketing of our service. This
                could include using your demographic location to improve
                targeting of our website and customise your visit.
              </li>
            </ul>
            <p></p>We will NEVER provide your personally identifiable
            information to any persons other than A law enforcement or
            regulatory agency at their request, third parties who provide
            services on our behalf such as payment processing, only if they
            agree not to miss use this information.<p></p>
            <p></p>We do not under any circumstances apart from the above give
            away or sell your information to any third parties.
            <p></p>
            <p></p>Your personal information is safe with us, your information
            is safely encrypted using SSL. Our service is not available to
            anyone under the age of 13, never will we intentionally maintain
            information about anyone under the age of 13.<p></p>
            <hr />
            <h2>Cookies</h2>
            <p>
              A cookie is a small text file that is stored on a users computer
              for record-keeping purposes. We use cookies on this site. We do
              not link the information we store in cookies to any personally
              identifiable information you submit while on our site. We use both
              session ID cookies and persistent cookies. We use session cookies
              to make it easier for you to navigate our site. A session ID
              cookie expires when you close you browser. A persistent cookie
              remains on your hard drive for an extended period of time much
              like the cookie used for our affiliate program. You can remove
              persistent cookies by following directions provided in your
              Internet browsers 'help' file. We set a persistent cookie to store
              your passwords, so you do not have to enter it more than once.
              Persistent cookies also enable us to track and target the
              interests of our users to enhance the experience on our site. If
              you reject cookies, you may still use our site, but you will be
              asked to enter your username and password again every time you
              closed your browser or your session has timed out.
            </p>
            <hr />
            <h2>Log Files</h2>
            <p>
              As is true of most Web sites, we gather certain information
              automatically and store it in log files. This information includes
              Internet protocol (IP) addresses, browser type, internet service
              provider (ISP), referring/exit pages, operating system, date/time
              stamp, and clickstream data. We use this information, which does
              not identify individual users, to analyze trends, to administer
              the site, to track users movements around the site and to gather
              demographic information about our user base as a whole. We do not
              link this automatically-collected data to personally identifiable
              information. IP addresses are tied to personally identifiable
              information to prevent and block abusers to keep using our
              services such as members who violates our terms of service. We use
              a third-party tracking service that uses cookies and log files to
              track non-personally identifiable information about visitors to
              our site in the aggregate of usage and volume statistics to
              determine how our users navigate through our website and to know
              how many users are using our services demographically. We use the
              information you provide about yourself or others to complete the
              transaction for which it is intended. This may include
              administering a service such as registration, email, forum, etc.,
              or contacting you if you have indicated your permission to do so
              by agreeing to accept further notification and service
              announcement from us on your member profile page. You can choose
              to opt-out from receiving any further notification from your
              member profile page. We do not share this information with outside
              parties.
            </p>
            <hr />
            <h2>Surveys</h2>
            <p>
              Upon using our free services, you may be requested to participate
              in our periodic internal surveys that are conducted by
              hostinggujju.com to determine your personal interests. These
              surveys are strictly voluntary. The data collected through these
              surveys will only be used to improve our products and services and
              will never be sold to any third parties.
            </p>
            <hr />
            <h2>Communications From The Site</h2>
            <p>
              When we receive email, we use return email addresses to reply with
              the information requested. Such addresses are not used for any
              other purpose and are not shared with outside parties. When
              supplying contact information to hostinggujju.com you can indicate
              your preference if you would like to be contacted to receive
              information and updates about our services by logging in to your
              hostinggujju.com account, going to your member profile page and
              choose not to receive any further notification emails from us or
              other information pertaining to hostinggujju.com. Information you
              submit to us will not be used for this purpose unless you indicate
              this as your preference. When supplying contact information to
              hostinggujju.com you can indicate your preference on your members
              profile page to participate in the hostinggujju.com newsletter.
              You will receive a confirmation email from us when placing an
              order on our site and paying through the payment gateway.
            </p>
            <hr />
            <h2>Newsletter</h2>
            <p>
              We provide you the opportunity to 'opt-in' and 'opt-out' of our
              newsletter at any time. Our newsletter is sent every month so it
              will certainly contain a lot of useful information and any offers
              we may have. If you no longer wish to receive our newsletter and
              promotional communications, you may opt-out of receiving them at
              any time.
            </p>
            <hr />
            <h2>Information Sharing and Disclosure</h2>
            <p>
              We collect information from our website visitors using Google
              Analytics. We use this information to better design our Web site
              and services. This information is never shared with any third
              party. We do not link aggregate user data with personally
              identifiable information.
            </p>
            <hr />
            <h2>Personally Identifiable Information</h2>
            <p>
              hostinggujju.com does not share, sell, rent, or trade personally
              identifiable information with third parties. We never use or share
              the personally identifiable information provided to us online in
              ways unrelated to the ones described within this privacy policy
              without also providing you an opportunity to opt-out or otherwise
              prohibit such unrelated uses.
            </p>
            <hr />
            <h2>How You Can Access /Correct Your Information</h2>
            <p>
              You can access all your personally identifiable information that
              we collect by logging into your password-protected
              hostinggujju.com account and selecting to update your 'My Details'
              We use this procedure to better safeguard your information. You
              can also correct factual errors in your personally identifiable
              information by sending us a request that credibly shows error. To
              protect your privacy and security, we will also take reasonable
              steps to verify your identity before granting access or making
              corrections.
            </p>
            <hr />
            <h2>Specific Terms and Conditions</h2>
            <p>
              hostinggujju.com considers email transmitted via our service to be
              the private correspondence between the sender and recipient. We
              will not monitor, edit or disclose the contents of a user`s
              private communications, except as required by law, to comply with
              legal process, if necessary to enforce the Service Agreement, to
              respond to claims that such contents violate the rights of
              third-parties, or to protect the rights or property of
              hostinggujju.com. By using the service you agree that technical
              processing of email communications is and may be required to send
              and receive messages, to conform to the technical requirements of
              connecting networks, to conform to the limitations of the Service,
              or to conform to other, similar technical requirements. By using
              the service you acknowledge and agree that hostinggujju.com do not
              endorse the content of any user communications and are not
              responsible or liable for any unlawful, harassing, libelous,
              privacy invading, abusive, threatening, harmful, vulgar, obscene,
              tortuous, or otherwise objectionable content, or content that
              infringes or may infringe the intellectual property or other
              rights of another.
            </p>
            <hr />
            <h2>Legal Disclaimer</h2>
            <p>
              We reserve the right to disclose your personally identifiable
              information as required by law and when we believe that disclosure
              is necessary to protect our rights and/or to comply with a
              judicial proceeding, court order, or legal process served on our
              Web site.
            </p>
            <hr />
            <h2>Security</h2>
            <p>
              The security of your personal information is important to us. We
              follow generally accepted industry standards to protect the
              personal information submitted to us, both during transmission and
              once we receive it. No method of transmission over the Internet,
              or method of electronic storage, is 100% secure, however we have
              taken every step possible to make your information safe. This
              website is protected by Secure Sockets Layer (SSL) to protect your
              information. Therefore, while we strive to use commercially
              acceptable means to protect your personal information, we cannot
              guarantee its absolute security.
            </p>
            <hr />
            <h2>Links To Other Sites</h2>
            <p>
              This Web site contains links to other sites that are not owned or
              controlled by hostinggujju.com. Please be aware that we,
              hostinggujju.com are not responsible for the privacy practices of
              such other sites. We encourage you to be aware when you leave our
              site and to read the privacy statements of each and every Web site
              that collects personally identifiable information. This privacy
              statement applies only to information collected by this Web site.
            </p>
            <hr />
            <h2>Changes To This Privacy Statement</h2>
            <p>
              If we decide to change our privacy policy, we will post those
              changes to this privacy statement, the home page, and other places
              we deem appropriate so that you are aware of what information we
              collect, how we use it, and under what circumstances, if any, we
              disclose it. We reserve the right to modify this privacy statement
              at any time, so please review it frequently. If we make material
              changes to this policy, we will notify you here, by email, or by
              means of a notice on our home page. For more information please do
              not hesitate to send your questions via our contact form.
            </p>
          </div>
        </section>
        <CustomSolution />
      </section>
    </React.Fragment>
  );
}
export default PrivacyPolicy;
