import React from "react";
import CustomSolution from "../../components/customSolution";
import PageTitle from "../../components/pageTitle";

function UnlimitedHostingPolicy() {
  return (
    <React.Fragment>
      <PageTitle
        title={"Unlimited Hosting Policy | Hosting Gujju | Made in India"}
      />
      <section id="content">
        <section className="content-row content-row-clouds">
          <div className="container">
            <header className="content-header content-header-large content-header-uppercase">
              <h1>Unlimited Hosting Policy</h1>
              <p>
                "Unlimited" usage policies and definitions apply only to shared
                and reseller hosting accounts.
              </p>
            </header>
          </div>
        </section>
        <section className="content-row">
          <div className="container">
            <h2>What "Unlimited" means</h2>
            <p>
              Unlimited Disk Space/Bandwidth/Website Plans. This means that we
              do not set a limit on the amount of bandwidth, websites or disk
              space you may use in the normal operation of a personal or small
              business website, provided it complies with this Agreement.
              However, in the event your service usage or content presents a
              risk to the stability, performance or uptime of our servers, data
              storage, networking or other infrastructure, you may be required
              to upgrade to a VPS, or we may take action to restrict the
              resources your website is utilizing.
            </p>
            <hr />
            <h2>What "Unlimited" DOES NOT mean</h2>
            <p>
              Hosting Gujju employs complex mechanisms to protect its hosting
              Subscribers and systems from any kind of abuse. Hosting Gujju's
              "Unlimited" hosting offering is not for single or few hosting
              subscribers to unfairly or adversely impact the experience of
              other hosting Subscribers. Hosting Gujju's service is offering
              shared hosting service, which means that multiple hosting
              Subscriber's websites are hosted from the same server and share
              server resources. Hosting Gujju's service is designed to meet the
              typical needs of small and medium sized businesses across globe.
              It is NOT intended to support the sustained demand of large
              enterprises, or non-typical applications better suited to a VPS or
              a dedicated server. Hosting Gujju will make every commercially
              reasonable effort to provide additional resources to Subscribers
              who are using their website(s) consistent with these Terms,
              including moving Subscribers to newer and bigger shared servers as
              necessary. However, in order to ensure a consistent and quality
              experience for all Subscribers, Hosting Gujju does place automated
              safeguards to protect against any website growing too quickly and
              adversely impacting the system until Hosting Gujju can evaluate
              said sites resource needs.
            </p>
            <hr />
            <h2>Unlimited Hosting Space; excessive SQL Database</h2>
            <p>
              Hosting Gujju does not set any specific limits on the amount of
              disk space a hosting subscriber can use for their website, nor
              does Hosting Gujju charge additional fees based on an increased
              amount of storage used, provided the hosting subscriber's use of
              storage complies with these Terms. Please note, however, that the
              Hosting Gujju service is designed to host websites. Hosting Gujju
              does NOT provide unlimited hosting for online storage, videos,
              images, audio, games, installers, executable files, backups, or
              archiving of electronic files, documents, log files, web spider or
              indexer, irc, torrent, file sharing, peer-to-peer activities,
              testing environment etc., and any such prohibited use of the
              Services will result in the termination of hosting subscriber's
              account, with or without notice. Accounts with an excessive
              database size (i.e., more than 1 GB) negatively affect the
              performance of the server. Hosting Gujju may request that the
              database size be reduced to ensure proper performance or may
              disable the Subscriber's account, with or without notice. Hosting
              subscriber's can create multiple databases however size of each
              database should not be more than 1 GB.
            </p>
            <hr />
            <h2>Unlimited File Transfer</h2>
            <p>
              Hosting Gujju does not set any limits on the amount of visitor
              traffic a website can receive or on the amount of content a
              hosting subscriber can upload to his/her/its website in a given
              month, nor does Hosting Gujju charge additional fees based on
              increased use of bandwidth, as long as the hosting subscriber's
              use of the Services complies with these Terms. In most cases, a
              hosting subscriber's website will be able to support as much
              traffic as the hosting subscriber can legitimately acquire.
              However, Hosting Gujju reserves the right to limit processor time,
              bandwidth, processes, or memory in cases where it is necessary to
              prevent negatively impacting other hosting subscribers.
            </p>
            <hr />
            <h2>Unacceptable Resource Usage</h2>
            <ul>
              <li>
                Run cron entries with intervals of less than fifteen (15)
                minutes.
              </li>
              <li>Run any MySQL queries longer than thirty (30) seconds.</li>
              <li>
                Use twenty-five percent (25%) or more of our system resources
                for longer than ninety (90) seconds at a time. Activities that
                could cause this excessive use, include but are not limited to:
                CGI scripts, FTP, PHP, HTTP, etc.
              </li>
              <li>
                The size of each mail box should not exceed 5GB and combined
                mail box size per domain should not exceed 50GB.
              </li>
            </ul>
            {/* <hr />
            <h2>Resource Allocation</h2>
            <div className="doctbl1-wrapr">
              <div className="doctbl1-hdrttl">
                <a href="hosting/web-hosting">Unlimited Hosting</a>
              </div>
              <table className="doctbl1">
                <tbody>
                  <tr>
                    <th>Server Resources</th>
                    <th>Tyro</th>
                    <th>Swift</th>
                    <th>Turbo</th>
                  </tr>
                  <tr>
                    <td>CPU Core</td>
                    <td>1.5</td>
                    <td>3</td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>RAM</td>
                    <td>2 GB</td>
                    <td>3 GB</td>
                    <td>5 GB</td>
                  </tr>
                  <tr>
                    <td>Concurrent connections (EP)</td>
                    <td>50</td>
                    <td>100</td>
                    <td>200</td>
                  </tr>
                  <tr>
                    <td>Number of processes (nPROC)</td>
                    <td>100</td>
                    <td>200</td>
                    <td>400</td>
                  </tr>
                  <tr>
                    <td>IO Limit</td>
                    <td>1MBPS</td>
                    <td>1MBPS</td>
                    <td>5MBPS</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="doctbl1-wrapr">
              <div className="doctbl1-hdrttl">
                <a href="hosting/shared-hosting">Shared Hosting</a>
              </div>
              <table className="doctbl1">
                <tbody>
                  <tr>
                    <th>Server Resources</th>
                    <th>Lite</th>
                    <th>Deluxe</th>
                    <th>Ultimate</th>
                  </tr>
                  <tr>
                    <td>CPU Core</td>
                    <td>0.5</td>
                    <td>2</td>
                    <td>2</td>
                  </tr>
                  <tr>
                    <td>RAM</td>
                    <td>512 MB</td>
                    <td>2 GB</td>
                    <td>3 GB</td>
                  </tr>
                  <tr>
                    <td>Concurrent connections (EP)</td>
                    <td>25</td>
                    <td>50</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Number of processes (nPROC)</td>
                    <td>50</td>
                    <td>75</td>
                    <td>100</td>
                  </tr>
                  <tr>
                    <td>IO Limit</td>
                    <td>1MBPS</td>
                    <td>1MBPS</td>
                    <td>1MBPS</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="doctbl1-wrapr">
              <div className="doctbl1-hdrttl">
                <a href="hosting/windows-hosting">Windows Hosting</a>
              </div>
              <table className="doctbl1">
                <tbody>
                  <tr>
                    <th>Server Resources</th>
                    <th>Tyro</th>
                    <th>Swift</th>
                    <th>Turbo</th>
                  </tr>
                  <tr>
                    <td>CPU Core</td>
                    <td>1.5</td>
                    <td>3</td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>RAM</td>
                    <td>2 GB</td>
                    <td>3 GB</td>
                    <td>5 GB</td>
                  </tr>
                  <tr>
                    <td>Concurrent connections (EP)</td>
                    <td>50</td>
                    <td>100</td>
                    <td>200</td>
                  </tr>
                  <tr>
                    <td>Number of processes (nPROC)</td>
                    <td>100</td>
                    <td>200</td>
                    <td>400</td>
                  </tr>
                  <tr>
                    <td>IO Limit</td>
                    <td>1MBPS</td>
                    <td>1MBPS</td>
                    <td>5MBPS</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="doctbl1-wrapr">
              <div className="doctbl1-hdrttl">
                <a href="hosting/reseller-hosting">Linux Reseller Hosting</a>
              </div>
              <table className="doctbl1">
                <tbody>
                  <tr>
                    <th>Resources Per cPanel Account</th>
                    <th>Pluto/Mars/Jupiter</th>
                    <th>Bronze/Silver/Gold/Platinum</th>
                  </tr>
                  <tr>
                    <td>CPU Core</td>
                    <td>1</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>RAM</td>
                    <td>1 GB</td>
                    <td>1 GB</td>
                  </tr>
                  <tr>
                    <td>Concurrent connections (EP)</td>
                    <td>30</td>
                    <td>30</td>
                  </tr>
                  <tr>
                    <td>Number of processes (nPROC)</td>
                    <td>50</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>IO Limit</td>
                    <td>1MBPS</td>
                    <td>1MBPS</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="doctbl1-wrapr">
              <div className="doctbl1-hdrttl">
                <a href="hosting/reseller-hosting">Linux Reseller Hosting</a>
              </div>
              <table className="doctbl1">
                <tbody>
                  <tr>
                    <th>Resources Per cPanel Account</th>
                    <th>Small/Medium/Large</th>
                  </tr>
                  <tr>
                    <td>CPU Core</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>RAM</td>
                    <td>512 MB</td>
                  </tr>
                  <tr>
                    <td>Concurrent connections (EP)</td>
                    <td>20</td>
                  </tr>
                  <tr>
                    <td>Number of processes (nPROC)</td>
                    <td>40</td>
                  </tr>
                  <tr>
                    <td>IO Limit</td>
                    <td>1MBPS</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="doctbl1-wrapr">
              <div className="doctbl1-hdrttl">
                <a href="hosting/windows-reseller-hosting">
                  Windows Reseller Hosting
                </a>
              </div>
              <table className="doctbl1">
                <tbody>
                  <tr>
                    <th>Resources Per Plesk Account</th>
                    <th>Pluto/Mars/Jupiter</th>
                    <th>Neo/Entry/Smart/Plus</th>
                  </tr>
                  <tr>
                    <td>CPU Core</td>
                    <td>1</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>RAM</td>
                    <td>1 GB</td>
                    <td>1 GB</td>
                  </tr>
                  <tr>
                    <td>Concurrent connections (EP)</td>
                    <td>30</td>
                    <td>30</td>
                  </tr>
                  <tr>
                    <td>Number of processes (nPROC)</td>
                    <td>50</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>IO Limit</td>
                    <td>1MBPS</td>
                    <td>1MBPS</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="doctbl1-wrapr">
              <div className="doctbl1-hdrttl">
                <a href="hosting/business-hosting">Business Hosting</a>
              </div>
              <table className="doctbl1">
                <tbody>
                  <tr>
                    <th>Server Resources</th>
                    <th>Alpha</th>
                    <th>Beta</th>
                    <th>Zeta</th>
                  </tr>
                  <tr>
                    <td>CPU</td>
                    <td>2</td>
                    <td>3</td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>RAM</td>
                    <td>2 GB</td>
                    <td>3 GB</td>
                    <td>4 GB</td>
                  </tr>
                  <tr>
                    <td>Concurrent connections (EP)</td>
                    <td>50</td>
                    <td>100</td>
                    <td>200</td>
                  </tr>
                  <tr>
                    <td>Number of processes (nPROC)</td>
                    <td>100</td>
                    <td>200</td>
                    <td>400</td>
                  </tr>
                  <tr>
                    <td>IO Limit</td>
                    <td>1MBPS</td>
                    <td>4MBPS</td>
                    <td>10MBPS</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="doctbl1-wrapr">
              <div className="doctbl1-hdrttl">
                <a href="hosting/wordpresss-hosting">WordPress Hosting</a>
              </div>
              <table className="doctbl1">
                <tbody>
                  <tr>
                    <th>Server Resources</th>
                    <th>Ace</th>
                    <th>Heart</th>
                    <th>Diamond</th>
                  </tr>
                  <tr>
                    <td>CPU</td>
                    <td>1</td>
                    <td>3</td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>RAM</td>
                    <td>512 MB</td>
                    <td>3 GB</td>
                    <td>4 GB</td>
                  </tr>
                  <tr>
                    <td>Concurrent connections (EP)</td>
                    <td>20</td>
                    <td>100</td>
                    <td>200</td>
                  </tr>
                  <tr>
                    <td>Number of processes (nPROC)</td>
                    <td>50</td>
                    <td>200</td>
                    <td>400</td>
                  </tr>
                  <tr>
                    <td>IO Limit</td>
                    <td>1MBPS</td>
                    <td>4MBPS</td>
                    <td>10MBPS</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr /> */}
            <p>Check our TOS, Acceptable Usage Policy and Privacy Policy.</p>
          </div>
        </section>
        <CustomSolution />
      </section>
    </React.Fragment>
  );
}
export default UnlimitedHostingPolicy;
