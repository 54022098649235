export const paths = {
  home: "/",
  linuxSharedWebHosting: "/linux-shared-web-hosting",
  linuxWordPressWebHosting: "/linux-wordpress-web-hosting",
  linuxResellerWebHosting: "/linux-reseller-web-hosting",
  windowsSharedWebHosting: "/windows-shared-web-hosting",
  windowsWordPressWebHosting: "/windows-wordpress-web-hosting",
  enterpriseWebHosting: "/enterprise-linux-web-hosting",
  linuxVps: "/linux-vps",
  containerVps: "/container-vps",
  windowsVps: "/windows-vps",
  storageVps: "/storage-vps",
  privacyPolicy: "/privacy-policy",
  termsOfService: "/terms-of-service",
  unlimitedHostingPolicy: "/unlimited-hosting-policy",
  acceptableUsagePolicy: "/acceptable-usage-policy",
};
