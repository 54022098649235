import React from "react";

function Header() {
  const handelNullClick = (e) => {
    e.preventDefault();
    console.log("onclick..");
  };

  return (
    <header
      id="header"
      className="header-dynamic header-light header-shadow-scroll"
    >
      <div className="container">
        <a className="logo" href="/">
          <img src="/assets/img/logos/logo.svg" alt="Hosting Gujju Logo" />
        </a>
        <nav>
          <ul className="nav-primary">
            <li>
              <a onClick={handelNullClick} href="/null">
                Hosting
              </a>
              <ul>
                <li>
                  <a onClick={handelNullClick} href="/null">
                    Linux (cPanel)
                  </a>
                  <ul>
                    <li>
                      <a href="/linux-shared-web-hosting">Shared Web Hosting</a>
                    </li>
                    <li>
                      <a href="/linux-wordpress-web-hosting">
                        Worpdress Hosting
                      </a>
                    </li>
                    <li>
                      <a href="/linux-reseller-web-hosting">Reseller Hosting</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a onClick={handelNullClick} href="/null">
                    Windows (Plesk)
                  </a>
                  <ul>
                    <li>
                      <a href="/windows-shared-web-hosting">
                        Shared Web Hosting
                      </a>
                    </li>
                    <li>
                      <a href="/windows-wordpress-web-hosting">
                        Worpdress Hosting
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="/enterprise-linux-web-hosting">
                    Enterprise Hosting (cPanel)
                  </a>
                </li>
              </ul>
            </li>
            {/* <li>
              <a onClick={handelNullClick} href="/null">
                VPS
              </a>
              <ul>
                <li>
                  <a href="/linux-vps">Linux VPS</a>
                </li>
                <li>
                  <a href="/windows-vps">Windows VPS</a>
                </li>
                <li>
                  <a href="/container-vps">Container VPS</a>
                </li>
                <li>
                  <a href="/storage-vps">Storage VPS</a>
                </li>
              </ul>
            </li> */}
            <li>
              <a onClick={handelNullClick} href="/null">
                Help
              </a>
              <ul>
                <li>
                  <a href="/clients/submitticket.php">Submit a Ticket</a>
                </li>
                <li>
                  <a href="/clients/contact.php">Contact Us</a>
                </li>
                <li>
                  <a href="/clients/serverstatus.php">Network</a>
                </li>
              </ul>
            </li>
            <li>
              <a onClick={handelNullClick} href="/null">
                How To
              </a>
              <ul>
                <li>
                  <a href="https://blog.hostingujju.com">Blog</a>
                </li>
                <li>
                  <a href="/clients/knowledgebase">Knowledgebase</a>
                </li>
              </ul>
            </li>
            <li>
              <a className="button button-secondary" href="/clients/login">
                <i className="fas fa-lock icon-left"></i>Client's Area
              </a>
            </li>
          </ul>
          <ul className="nav-secondary">
            <li>
              <a href="/clients/submitticket.php?step=2&deptid=1">
                <i className="fas fa-comment icon-left"></i>Support Ticket
              </a>
            </li>
            <li>
              <a href="/clients/knowledgebase">
                <i className="fas fa-question-circle icon-left"></i>Knowledge
                Base
              </a>
            </li>
            <li>
              <a href="/clients/serverstatus.php">
                <i className="fas fa-check icon-left"></i>Service Status
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
