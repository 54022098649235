import React from "react";
import CustomSolution from "../../components/customSolution";
import PageTitle from "../../components/pageTitle";

function AcceptableUsagePolicy() {
  return (
    <React.Fragment>
      <PageTitle
        title={"Acceptable Usage Policy | Hosting Gujju | Made in India"}
      />
      <section id="content">
        <section className="content-row content-row-clouds">
          <div className="container">
            <header className="content-header content-header-large content-header-uppercase">
              <h1>Acceptable Usage Policy</h1>
            </header>
          </div>
        </section>
        <section className="content-row">
          <div className="container">
            <h2>General Acceptable Use Policy</h2>
            <p>
              This AUP governs the use of Hosting Gujju's hosting service.
              Violation of this AUP may result in suspension or termination of
              your service. In the event of a dispute between you and Hosting
              Gujju regarding the interpretation of this AUP, Hosting Gujju's
              interpretation, in its reasonable commercial judgment, shall
              govern.
            </p>
            <p>
              The following is the entire Acceptable User Policy ("AUP")
              agreement between Hosting Gujju and the personal or corporate
              account holder. Hosting Gujju provides managed web hosting and
              domain name service. Hosting Gujju has certain legal and ethical
              responsibilities consisting with the use of its servers and
              equipment involved in these services. Hosting Gujju's general
              policy is to act as a provider of Internet presence. Hosting Gujju
              reserves the rights to suspend or cancel a customer's access to
              any or all services provided by Hosting Gujju when we decide that
              the account has been inappropriately used.
            </p>
            <hr />
            <h2>What is Appropriate Usage?</h2>
            <p>
              Hosting Gujju web hosting solution has specifically been designed
              to serve the web hosting needs of small and independently owned
              and operated businesses and organizations throughout the world.
              Since you will be hosting your website with several other clients
              on our servers in a virtual community, our offerings are really
              not intended to support enterprise level or large company
              requirements. For that, we recommend purchasing at least a virtual
              private server, cloud or dedicated server solution.
            </p>
            <p>
              Hosting Gujju hosting is a shared web hosting service, which
              ultimately means a number of customers' websites are hosted within
              the same server environment. To ensure that our hosting is
              first-class, reliable, and available to all customers within that
              server community, an individual customer's website usage cannot
              adversely affect the performance of other customers' sites. We
              monitor our servers 24/7/365 and in no way can one customer
              adversely affect the performance of that server. If this happens,
              we will take preventative measures to ensure that the entire
              community is protected.
            </p>
            <p>
              Lastly, our web hosting service is intended to host websites, not
              backup or storage facility for users' data. Using your web hosting
              account primarily as an online storage space for archiving
              electronic files and images is strictly prohibited. Our servers
              are for web hosting usage only.
            </p>
            <hr />
            <h2>Offensive Content</h2>
            <p>
              You may not publish or transmit via Hosting Gujju's service any
              content that Hosting Gujju reasonably believes:
            </p>
            <ul>
              <li>constitutes child pornography;</li>
              <li>constitutes pornography;</li>
              <li>
                is excessively violent, incites violence, threatens violence, or
                contains harassing content or hate speech;
              </li>
              <li>
                is unfair or deceptive under the consumer protection laws of any
                jurisdiction, including chain letters and pyramid schemes;
              </li>
              <li>is defamatory or violates a person's privacy;</li>
              <li>
                creates a risk to a person's safety or health, creates a risk to
                public safety or health, compromises national security, or
                interferes with a investigation by law enforcement;
              </li>
              <li>
                improperly exposes trade secrets or other confidential or
                proprietary information of another person;
              </li>
              <li>
                is intended to assist others in defeating technical copyright
                protections;
              </li>
              <li>
                clearly infringes on another person's trade or service mark,
                patent, or other property right;
              </li>
              <li>
                promotes illegal drugs, violates export control laws, relates to
                illegal gambling, or illegal arms trafficking;
              </li>
              <li>
                is otherwise illegal or solicits conduct that is illegal under
                laws applicable to you or to Hosting Gujju;
              </li>
              <li>
                is otherwise malicious, fraudulent, or may result in retaliation
                against Hosting Gujju by offended viewers;
              </li>
            </ul>
            <p>Hosting Gujju does not accept material such as:</p>
            <p>
              Proxy, Warez, Escrow, High Yield Investment Programs(HYIP),
              Multi-level marketing (MLM), Gaming servers/applications, illegal
              Gambling and Lottery sites.
            </p>
            <p>
              Hosting Gujju can terminate service for any of the above reasons
              and it's at Hosting Gujju's sole discretion to issue a refund.
            </p>
            <hr />
            <h2>Security</h2>
            <p>
              You must take reasonable security precautions. You must protect
              the confidentiality of your password, and you should change your
              password periodically.
            </p>
            <hr />
            <h2>Bulk Commercial Email</h2>
            <p>
              Bulk commercial email, mailing lists and email reflectors are
              allowed on Hosting Gujju's servers, we do however allow a maximum
              of 500 emails per hour per account. For any emails sent from your
              Hosting Gujju account you must adhere to the following rules:
            </p>
            <ul>
              <li>
                Your intended recipients have given their consent to receive
                e-mail via some affirmative means, such as an opt-in procedure;
              </li>
              <li>
                Your procedures for soliciting consent include reasonable means
                to ensure that the person giving consent is the owner of the
                e-mail address for which the consent is given;
              </li>
              <li>
                You may not obscure the source of your e-mail in any manner.
              </li>
              <li>
                You otherwise comply with the CAN SPAM Act and other applicable
                law.
              </li>
            </ul>
            <p>
              These policies apply to messages sent using your Hosting Gujju
              service, or to messages sent from any network by you or any person
              on your behalf that directly or indirectly refer the recipient to
              a site hosted via your Hosting Gujju service. In addition, you may
              not use a third party e-mail service that does not practice
              similar procedures for all its customers.
            </p>
            <hr />
            <h2>Unsolicited E-Mail</h2>
            <p>
              You may not send any unsolicited e-mail, either in bulk or
              individually, to any person who has indicated that they do not
              wish to receive it. You must comply with the rules of any other
              network you access or participate in using your Hosting Gujju's
              services.
            </p>
            <hr />
            <h2>Material Protected By Copyright</h2>
            <p>
              You may not publish, distribute, or otherwise copy in any manner
              any music, software, art, or other work protected by copyright law
              unless:
            </p>
            <ul>
              <li>
                you have been expressly authorized by the owner of the copyright
                for the work to copy the work in that manner.
              </li>
              <li>
                you are otherwise permitted by established United States
                copyright law to copy the work in that manner.
              </li>
            </ul>
            <p>
              Hosting Gujju will terminate the service of repeat copyright
              infringers.
            </p>
            <hr />
            <h2>Other</h2>
            <p>
              You must have valid and current information on file with your
              domain name registrar for any domain hosted on the Hosting Gujju
              network.
            </p>
            <hr />

            <h2>Disclaimer</h2>
            <p>
              Hosting Gujju is under no duty, and does not by this AUP undertake
              a duty, to monitor or police our customers' activities and
              disclaims any responsibility for any misuse of the Hosting Gujju
              network.
            </p>
            <hr />
            <h2>Internet Abuse</h2>
            <p>
              You may not engage in illegal, abusive, or irresponsible behavior,
              including:
            </p>
            <ul>
              <li>
                Unauthorized access to or use of data, systems or networks,
                including any attempt to probe, scan or test the vulnerability
                of a system or network or to breach security or authentication
                measures (including those belonging to Hosting Gujju and its
                customers) without express authorization of the owner of the
                system or network;
              </li>
              <li>
                monitoring data or traffic on any network or system without the
                authorization of the owner of the system or network;
              </li>
              <li>
                Interference with service to any user, host or network
                including, without limitation, mail bombing, flooding,
                deliberate attempts to overload a system and broadcast attacks;
              </li>
              <li>
                Use of an Internet account or computer without the owner's
                authorization, including, but not limited to Internet scanning
                (tricking other people into releasing their passwords), password
                robbery, security hole scanning, and port scanning;{" "}
              </li>
              <li>
                Forging of any TCP-IP packet header or any part of the header
                information in an e-mail or a newsgroup posting; or
              </li>
              <li>
                Any conduct that is likely to result in retaliation against the
                Hosting Gujju network.
              </li>
              <li>
                Use of Hosting Gujju's network in a way that unreasonably
                interferes with Hosting Gujju's other customers use of the
                network.
              </li>
            </ul>
            <hr />
            <h2>Newsgroup, Chat Forums, Other Networks</h2>
            <p>
              You must comply with the rules and conventions for postings to any
              bulletin board, chat group or other forum in which you
              participate, such as IRC and USENET groups including their rules
              for content and commercial postings. These groups usually prohibit
              the posting of off-topic commercial messages, or mass postings to
              multiple forums.
            </p>
          </div>
        </section>
        <CustomSolution />
      </section>
    </React.Fragment>
  );
}
export default AcceptableUsagePolicy;
