import React from "react";
import CustomSolution from "../../components/customSolution";
import PageTitle from "../../components/pageTitle";

function TermsOfService() {
  return (
    <React.Fragment>
      <PageTitle title={"Terms of Service | Hosting Gujju | Made in India"} />
      <section id="content">
        <section className="content-row content-row-clouds">
          <div className="container">
            <header className="content-header content-header-large content-header-uppercase">
              <h1>Terms of Service</h1>
            </header>
          </div>
        </section>
        <section className="content-row">
          <div className="container">
            <p>
              The following Terms of Service ("TOS," "Terms" or "Agreement")
              apply to your use of our website and all services, features and/or
              content provided by Hosting Gujju (“Hosting Gujju,” "us,", "our"
              ). "Hosting Gujju" is the name of "
              <a href="https://hostinggujju.com">https://hostinggujju.com</a>".
              By purchasing one or more Services from Hosting Gujju, you declare
              that you have read, understood and agree to be bound by this TOS.
              The latest version of our TOS is always available on the Hosting
              Gujju website. It is essential that you read this TOS prior to
              purchasing any Service(s) from Hosting Gujju.
            </p>
            <hr />
            <h2>Customer Agreement</h2>
            <p>
              The TOS apply to all Services provided by Hosting Gujju to you
              throughout the entire Term or Renewal Term. All customers should
              agree on all terms and conditions stated prior to their signing up
              for any of the hosting plans and services mentioned on Hosting
              Gujju. You shall agree to use only licensed and legal third party
              softwares in connection with your use of our services. Hosting
              Gujju reserves the right to suspend or cancel a customer's access
              to any or all services provided, at any time, or when Hosting
              Gujju decides that the account has been inappropriately used or
              otherwise.
            </p>
            <hr />
            <h2>Content</h2>
            <p>
              Hosting Gujju reserves the right to refuse service and/or access
              to its servers to anyone. The services provided by us must be used
              for lawful purposes only. Transmission, storage, or presentation
              of any information, data or material in violation of any
              applicable law or regulation is prohibited.
            </p>
            <p>
              The activities listed below are meant to provide you with examples
              of activities and content that are strictly prohibited.
            </p>
            <p>
              Topsites, ponzi or pyramid schemes, prime banks programs, bank
              debentures/bank debenture trading programs, cryptocurrency/bitcoin
              miners, forex, e-gold, proxy, bots, spam bots, mail bombers, port
              scanners, IP scanners, mail harvesting scripts, brute force
              scripts, chat rooms, banner rotators, file dumps, using disk space
              as secondary storage, sales of illegal pharma, drugs, explosives,
              anonymous or bulk sms gateways, malware, warez, phishing
              materials, lottery, gambling, illegal downloads, pirated software
              distributions, escrow, high yield investment programs, adult
              thumbnail galleries/banner exchanges, streaming or broadcasting of
              live copyrighted events. Websites promoting illegal activities or
              linking to other websites that promote illegal activities.
            </p>
            <p>
              Website content that promotes or advocates human trafficking in
              any way shape or form as determined by Hosting Gujju’s sole
              discretion, sites that promote prostitution, or escort services.
            </p>
            <p>
              Any website found to host child pornography, link to child
              pornography or exploitative content will be suspended immediately
              without notice or warning.
            </p>
            <p>
              Adult materials – Includes all pornography, erotic images, or
              otherwise lewd or obscene content. The designation of “adult
              material” is left entirely to the discretion of Hosting Gujju.
            </p>
            <p>
              Embedding or uploading audio/video/high resolution images on
              website or linking to other websites that promotes
              audio/video/high resolution images.
            </p>
            <p>
              Illegal Material - This includes copyrighted works, commercial
              audio, video, or music files, and any material in violation of any
              regulation or material that is perceived to be misleading.
            </p>
            <p>
              Warez - This includes, but is not limited to, pirated software,
              ROMS, emulators, phreaking, hacking, password cracking, IP
              spoofing and encrypting of any of the above. It also includes any
              sites which provide "links to" or "how to" information about such
              material.
            </p>
            <p>
              Games - We strictly do not allow to host any kind of games on our
              servers. Your hosting account would be immediately terminated if
              we found any gaming related stuff in your hosting account.
            </p>
            <p>
              IRC Hosts - Hosting an IRC server that is part of or connected to
              another IRC network or server or have a connection to an IRC
              network. Servers or Virtual hosting accounts, found to be
              connecting to or part of these networks will be immediately
              removed from our network without notice. The server would not be
              reconnected to the network until such time that you agree to
              completely remove any and all traces of the irc server, and agree
              to let us have access to your server to confirm that the content
              has been completely removed. Any server guilty of a second
              violation would result in immediate account termination.
            </p>
            <p>
              Hosting Gujju reserves the right to deem any other content or
              literature as "prohibited" if found to be indecent, rude, hate
              content &amp; generally unacceptable.
            </p>
            <p>
              Refer General Acceptable Use Policy for complete details on
              prohibited content and activities.
            </p>
            <p>
              In compliance with DMCA act, Hosting Gujju has adopted procedures
              for parties who believe that a hosted site on our service is
              infringing their copyrights. All copyright complaints must be
              submitted to abuse@Hosting Gujju.com
            </p>
            <hr />
            <h2>Abuse Of Resources</h2>
            <ul>
              <li>
                Any attempts to compromise or actual compromise of a network
                device belonging to Hosting Gujju or any other company,
                individual, or other entity is strictly prohibited. This offense
                also includes port scanning, IP range scanners and vulnerability
                scanning. All infractions and or suspected infractions will be
                vigorously investigated and may result in immediate termination
                of service.
              </li>
              <li>
                Denial of Service (DOS) attacks directed at Hosting Gujju, or
                any attempts to launch a Denial of Service attack from our
                servers are strictly prohibited. All infractions and or
                suspected infractions will be vigorously investigated and may
                result in immediate termination of service.
              </li>
              <li>
                Hosting any software, source code or any other material that is
                illegal or could be used to commit an illegal act is strictly
                prohibited. This includes but is not limited to software
                exploits; exploit source code, hacking tutorials, hacking tools,
                viruses and Trojans, bomb making and similar device tutorials.
                All infractions and or suspected infractions will be vigorously
                investigated and may result in immediate termination of service.
              </li>
              <li>
                The hosting of material that infringes on any copyright,
                trademark and or service mark is strictly prohibited. This also
                includes the storage and transmission of pirated movies, music,
                software, ring tones or anything else considered "Warez". Any
                authentic complaint received will be investigated and may result
                in termination of service.
              </li>
              <li>
                The hosting of defamatory, scandalous, or private information
                about a person without their consent, intentionally inflicting
                emotional distress, or violating trademarks, copyrights, or
                other intellectual property rights.
              </li>
            </ul>
            <p>
              Any attempts to undermine or cause harm to any of our servers is
              strictly prohibited. We hold no responsibility for the use of our
              clients' accounts. Any account that abuses the resources and
              actions are not taken to desist the activity, calls for immediate
              deactivation without any refund. Any site using what we deem to be
              using excessive CPU cycles or any resources that cause strain to
              other sites may also be offered new terms ( i.e.,. upgrade).
              Although we offer 99.95% up time we can not fully guarantee this,
              this is due to the updates and up-gradation we carry out which is
              also a part of our maintenance.
            </p>
            <p>
              If any client is in any way disrespectful towards any member of
              the Hosting Gujju staff we have full right to remove them from our
              services without any refund.
            </p>
            <hr />
            <h2>
              Unsolicited commercial e-mail/Unsolicited bulk e-mail (SPAM)
            </h2>
            <p>
              Use of our service to transmit any unsolicited commercial or
              unsolicited bulk e-mail is expressly prohibited. Violation of this
              SPAM policy will result in severe penalties.
            </p>
            <p>
              Upon notification of an alleged violation, we will initiate a full
              scale investigation during which, we may restrict customer's
              access to the network to prevent further violations. If a customer
              is found to have violated our policy, we may, at our sole
              discretion, restrict, suspend or terminate the guilty hosting
              account. Further more, we reserve the right to pursue civil
              remedies for any costs associated with the investigation of a
              substantiated policy violation. We will notify law enforcement
              officials if the violation is believed to be a criminal offense.
              Anyone using our service to host websites or services that support
              spammers or cause any of our IP space to be listed in any of the
              various Spam Databases will have their account(s) immediately
              suspended and an administrative fee of $250.00 will be charged to
              your account. The account will not be unsuspended until such time
              that you agree to remove any and all traces of the offending
              material immediately upon reconnection. First violations of this
              policy will result in a warning and your account will be reviewed
              for possible immediate termination. A second violation may result
              in an immediate termination of your account and the administrative
              fees will be $500. Severe violations may result in immediate and
              permanent removal of the account(s) from our network without
              notice to the customer.
            </p>
            <p>
              We have a zero tolerance policy for the use of our service towards
              posting of messages or commercial advertisements which violate the
              rules, regulations, or charter of any newsgroups or mailing list.
              Commercial messages that are appropriate under the rules of a
              newsgroup or mailing list or that are solicited by the recipients
              are permitted.
            </p>
            <p>
              We also prohibit hosting of publicly-accessible "open relay" SMTP
              or anonymous remailer service for any purpose, cause, or reason
              what-so-ever.
            </p>
            <hr />
            <h2>Bandwidth</h2>
            <p>
              If the customers account exceeds the bandwidth limit, we would
              inform the customer. If the customer does not wish to purchase
              extra bandwidth or upgrade his account and continues to exceed
              their limit, the account shall be suspended until the customer
              takes measures to ensure that the limit wouldn’t exceed again or
              when the account is upgraded, which ever is earlier. The account
              would be reactivated in the following month.
            </p>
            <hr />
            <h2>Account Verification</h2>
            <p>
              If the customer fails to complete the account verification process
              then no refund would be provided to such customers under any
              circumstances.
            </p>
            <hr />
            <h2>VPS Rules</h2>
            <p>
              We generally recommend a control panel to manage and operate the
              VPS. All customers who have a plain VPS would have to configure
              the DNS, user accounts, mail accounts and other such activities
              pertaining to the configuration of such VPS' themselves. The
              support team will not be responsible for this.
            </p>
            <hr />
            <h2>
              Commercial Advertising- Email (SPAM)/UNSOLICITED COMMERCIAL EMAIL
              (UCE)
            </h2>
            <p>
              Spamming, sending unsolicited emails from our servers or using
              email addresses that are maintained by us is STRICTLY prohibited
              and will qualify your site for immediate deactivation with no
              refund. Hosting Gujju would be the sole arbiter as to what
              constitutes a violation of this provision.
            </p>
            <p>
              In order to ensure that our shared servers, cloud &amp; vps nodes
              as well as dedicated server hardware node give us optimum
              performance we have set limits on the number of outgoing mails
              which can be sent per hour. Accordingly, shared &amp; reseller
              customers would be allowed to send 300 outgoing mails per hour,
              our VPS customers would be able to send 1000 outgoing mails every
              hour and the Cloud hosting customers may send 1500 outbound mails
              per hour. Customers having dedicated servers may send one email
              every second i.e. 3600 mails per hour.
            </p>
            <p>
              We do not guarantee Inbox email delivery with any of our hosting
              plans.
            </p>
            <hr />
            <h2>Backup Policy</h2>
            <p>
              Your use of the Services is at your sole risk. You agree to
              back-up all of your User Content so that you can access and use it
              when needed. Hosting Gujju does not warrant that it backs-up any
              Account or User Content, and you agree to accept as a risk the
              loss of any and all of your User Content.
            </p>
            <p>
              You as a Hosting Gujju User agree that in no case, Hosting Gujju
              can be held responsible for the loss of data in any circumstances.
            </p>
            <p>
              Clients who have subscribed for paid backup service with shared
              and reseller hosting plans. For such clients, we take daily backup
              of database with retention period of 7 days. Website backup will
              be taken weekly with retention period of 1 day. Any backup copy
              maintained by Hosting Gujju will not have any emails or media file
              included in it.
            </p>
            <p>
              Clients using cPanel Backup Manager tool for backups should
              download the backup from the server and delete it. On every
              Sunday, all the backup files are removed from the server without
              any notification. You are not allowed to use the hosting space to
              store backups.
            </p>
            <hr />
            <h2>Unlimited Hosting</h2>
            <p>
              "Unlimited" usage policies and definitions apply only to shared
              and reseller hosting accounts.{" "}
              <a href="unlimited-hosting-policy.php">Click here</a> to read the
              unlimited hosting policy.
            </p>
            <p>
              The size of each mail box should not exceed 5GB and combined mail
              box size per domain should not exceed 50GB. This is applicable for
              WordPress hosting, Business hosting, Linux &amp; Windows based
              shared hosting plans.
            </p>
            <hr />
            <h2>Uptime Guarantee</h2>
            <p>
              Any service interruptions or downtime due to scheduled maintenance
              by Hosting Gujju or our network providers will not count towards
              the uptime guarantee. Hosting Gujju is not liable in any way for
              failure of third party services.
            </p>
            <p>
              Hosting Gujju will not be liable for failure or delay in
              performing its obligations if such failure or delay is due to
              circumstances beyond its reasonable control, including but not
              limited to acts of any governmental body, war, insurrection,
              sabotage, embargo, fire, flood, strike or other labour
              disturbance, interruption of or delay in transportation,
              unavailability of, interruption or delay in telecommunications or
              third party services (including DNS propagation), failure of third
              party software or hardware or inability to obtain raw materials,
              supplies or power used in or equipment needed for provision of the
              Services.
            </p>
            <hr />
            <h2>Security</h2>
            <p>
              You are responsible for any misuse of your account therefore, you
              must take steps to ensure that others do not gain unauthorized
              access to your account. In addition, you may not use your account
              to breach the security of another account or attempt to gain
              unauthorized access to another network or server.
            </p>
            <p>
              Your password provides access to your account. It is your
              responsibility to keep your password secure.
            </p>
            <p>
              Sharing your password and account access with unauthorized users
              is not recommended. You should take care and prevent others from
              using your account since you will be responsible for the
              consequences.
            </p>
            <p>
              Attempting to obtain another user's account password is strictly
              prohibited, and will result in termination of service.
            </p>
            <p>
              You must adopt adequate security measures to prevent or minimize
              unauthorized use of your account.
            </p>
            <p>
              You may not attempt to circumvent user authentication or security
              of any host, network or account. This includes, but is not limited
              to, accessing data not intended for you, logging into or making
              use of a server or account you are not expressly authorized to
              access, or probing the security of other networks. Use or
              distribution of tools designed for compromising security is
              prohibited. Examples of these tools include, but are not limited
              to, password guessing programs, cracking tools or network probing
              tools.
            </p>
            <p>
              You may not attempt to interfere with service to any user, host or
              network ("denial of service attacks"). This includes, but is not
              limited to, "flooding" of networks, deliberate attempts to
              overload a service, and attempts to "crash" a host. Users who
              violate systems or network security may incur criminal or civil
              liability. Hosting Gujju will cooperate fully with investigations
              of violations of systems or network security at other sites,
              including cooperating with law enforcement authorities in the
              investigation of suspected criminal violations.
            </p>
            <hr />
            <h2>Billing Policy</h2>
            <p>
              If paying via credit card, debit card, net banking, PayPal the
              account will be setup immediately once the payment is confirmed.
              If paying by a cheque, please allow 5 days for the cheque to
              clear. If paying via postal orders or cash your account will be
              set up on the same day.
            </p>
            <p>
              The 30 day money back guarantee is only applicable for first-time
              signups. Second time and subsequent sign-ups are not eligible for
              the same. For instance, if you've had an account with us before,
              cancelled and signed up again, you will not be eligible for a
              refund or if you have opened a second account with us.
            </p>
            <p>
              Refund is not applicable on Domain registration/transfer/renewal,
              cPanel, Plesk, Control Panel, LiteSpeed, Softaculous, Cloud, VPS,
              Dedicated Server, Paid Support, SSL Certificates, Sitelock,
              SpamExperts, WHMCS, Windows OS, SQL, G Suite, Office 365 and any
              other paid licenses.
            </p>
            <p>
              The modifications done in pricing pattern or policy by the
              software license company will take effect immediately on Hosting
              Gujju clients.
            </p>
            <p>
              The money back guarantee is provided on Shared hosting, Reseller
              hosting, WordPress hosting and Email hosting.
            </p>
            <p>
              No refund will be made if your Service(s) are suspended or
              terminated for cause.
            </p>
            <p>
              There is limitation of every hosting platform. On an OpenVZ VPS,
              GUI is not supported and it won’t be possible to upgrade the
              kernel or change BIOS setting. Kernel upgrade is possible on KVM
              Cloud instances but you cannot change the BIOS settings. You won't
              be eligible for refund in such cases.
            </p>
            <p>
              The refund will not cover any kind of taxes, setup fees, refund
              processing fee, postal charges or any kind of payment processing
              charges or bank fee(s). There will be no refunds issued in case
              the web hosting account was involved in violation of our terms of
              service or any kind of illegal or system resources over-usages or
              after 30 days period or beyond coverage of our refund policy as
              noted here.
            </p>
            <p>
              The free domain is offered with Swift and Turbo plan of shared
              hosting. One free .COM or .IN domain is offered with purchase of
              every new Swift/Turbo plan for 1/2/3 years. Domain name will be
              free for the first year and renewal charges will be applicable
              from the second year.
            </p>
            <p>
              You must add the domain name into your cart with the hosting plan.
              You cannot claim the free domain later.
            </p>
            <p>
              If the web hosting service includes free domain name, and you
              cancel the web hosting service, the domain charges (standard rate
              as per website) would be deducted from the refund amount. If the
              refund amount is less than the domain name price, you will have to
              pay the difference or forfeit the domain. The ownership of the
              domain will remain with Hosting Gujju if you fail to pay the
              difference amount.
            </p>
            <p>
              Transfer fees would be applicable, if you wish to transfer the
              free domain name to some other provider. Without transfer fees the
              domain will not be released.
            </p>
            <p>
              One free WHMCS Starter or ClientExec license is included in
              reseller hosting plan (Pluto/Mars/Jupiter). WHMCS starter license
              allows you to manage upto 250 clients. You can upgrade the WHMCS
              license from us by paying the appropriate license cost. If the
              reseller hosting plan is cancelled within first 30 days then WHMCS
              Starter or ClientExec license cost will be deducted from the
              refund amount.
            </p>
            <p>
              The payments made through bank transfer, cheque, money order, cash
              deposit, DD are non-refundable. Refund will be added as credit to
              the hosting account.
            </p>
            <p>
              The refund request placed after 30 days will not be processed and
              you will not be eligible for any refund.
            </p>
            <p>The cancellation request should be sent through client area.</p>
            <p>
              After sending the cancellation request, it would take 4 - 5
              working days to complete the refund process.
            </p>
            <hr />
            <h2>Account Termination</h2>
            <p>
              Your hosting account at Hosting Gujju can get terminated due to
              following reasons:
            </p>
            <p>
              <strong>You decide to discontinue :</strong> At any point of time,
              during your subscription, if you no more want to continue with our
              services and want to place a cancellation request with immediate
              termination, your hosting account will be suspended right away and
              will be terminated within 48 hours.
            </p>
            <p>
              <strong>Cancellation upon expiration :</strong> If you place a
              cancellation request to discontinue our services after your
              subscription expires, your hosting account will be suspended at
              the end of the subscription and will be terminated within 48 hours
              (after the expiration date).
            </p>
            <p>
              <strong>You did not renew :</strong>
              In case you fail to renew your hosting account on the due date,
              your hosting account will be suspended after 24 hrs and will be
              terminated within 48 hours (after the expiration date).
            </p>
            <p>
              <strong>You violate our Terms of service :</strong>
              If you violate our terms of service during your subscription
              period at Hosting Gujju it will result in immediate account
              suspension and termination. As soon as we've been notified about
              the violation of service, we would usually take instant action.
            </p>
            <p>
              <strong>Potential fraud :</strong>
              We have an anti-fraud system that monitors all the orders as soon
              as they are placed. During such an occurrence, we will carry out a
              verification process manually. In this process, if we ask for
              certain information and it is not provided the account suspension
              and termination will be done immediately.
            </p>
            <p>
              <strong>A Dispute or ChargeBack :</strong>
              During the violation of our Terms of Service, we would initially
              suspend and give a 7 days timeframe to resolve the concern with
              Hosting Gujju before the termination occurs. If still, it remains
              unsolved termination of your hosting account will take place.
            </p>
            <p>
              <strong>Note :</strong>
              It is advised to maintain a back-up copy of your hosting account
              to avoid losing your data because of the termination. All your
              files will be deleted from the server, once your hosting account
              is terminated and Hosting Gujju shall not be liable for the same.
            </p>
            <hr />
            <h2>Indemnification</h2>
            <p>
              Customer agrees that they will protect, indemnify, save and hold
              Hosting Gujju harmless from any and all stipulations, liabilities,
              losses, expenses and claims, as well as reasonable attorney's fees
              assessed against Hosting Gujju, its agents, customers, officers,
              employees and administration that may arise or result from any
              service provided or performed or agreed to be performed or any
              product sold by it's customers, agents, employees or assigns.
            </p>
            <p>
              Customer agrees to defend, indemnify and hold Hosting Gujju
              harmless against liabilities arising out of -
            </p>
            <ul>
              <li>
                Any injury to person or property caused by any products sold or
                distributed in association with Hosting Gujju's Services;
              </li>
              <li>
                The loss of any electronic files furnished by customer (Or
                customer's customer);
              </li>
              <li>
                Copyright violation and any defective products sold to a
                customer from Hosting Gujju's Services
              </li>
            </ul>
            <hr />
            <h2>Limitation of Liability</h2>
            <p>
              To the maximum extent permitted by applicable law, and without
              affecting your rights as a Consumer, you agree that you will not
              under any circumstances, including negligence, hold Hosting Gujju,
              its officers, directors, employees, licensors, agents,
              subcontractors and/or third party service providers liable for any
              direct or indirect damages of any nature and type suffered by the
              Customer of third parties, including, but not limited to, damages
              for loss of profits, cost savings, revenue, business, data or use,
              or any other pecuniary loss that may result from: delays,
              malfunctions, suspension and any other interruption in the
              provision of the Service(s) due to events beyond our reasonable
              control (for example: force majeure, third party conduct/acts,
              including Hosting Gujju's licensors and suppliers, faults and
              malfunctions of the machines, software and other equipment,
              whether owned by us or our licensors/suppliers; acts and/or
              omissions made by Customers and in contrast with the obligations
              undertaken under these TOS); data loss due to hardware or software
              failure; any information, data, content in or accessed through the
              Services; any action, information or instruction provided as part
              of our technical support Services; your use of the Service(s).
            </p>
            <p>
              You agree that the foregoing limitations apply whether based on
              warranty, contract or tort or any other legal theory and apply
              even if we have been advised of the possibility of such damages.
              In no event, we will be liable to you in the aggregate with
              respect to any and all breaches, defaults, or claims of liability
              under these TOS or under any other agreement or document for an
              amount greater than the fees actually paid by you to us for the
              respective Service(s) during the twelve month period preceding a
              claim giving rise to such liability. Some jurisdictions do not
              allow the limitation or exclusion of liability for incidental or
              consequential damages. You agree that in those jurisdictions, our
              liability will be limited to the extent permitted by law and your
              rights as a Consumer will not be affected.
            </p>
            <hr />
            <h2>Dispute Resolution. Jurisdiction</h2>
            <p>
              In the event of any dispute, controversy or claim arising out of
              or related to this Agreement, you and Hosting Gujju shall use
              reasonable effort to settle such disputes or differences. To this
              effect, we shall consult and negotiate each other with the aim to
              reach a solution satisfactory to each Party.
            </p>
            <p>
              You agree that the Courts of Nashik, Maharashtra (India) shall
              have the sole jurisdiction over all disputes and other matters
              relating to the execution, interpretation, enforcement and
              termination of this Agreement or any other document entered into
              by the Parties related thereto. All disputes and other matters
              relating to the interpretation and enforcement of thеsе TOS as
              well as any other document entered into by the Parties shall be
              governed by the laws of India.
            </p>
            <hr />
            <h2>Change of TOS</h2>
            <p>
              Hosting Gujju may modify these TOS at any time with immediate
              effect. Where the change in Terms is required by law or related to
              the addition of a new service, extra functionality to the existing
              Service(s) or any other change which neither reduces your rights
              nor increases your responsibilities, the TOS will be changed
              without prior notice to you and shall have immediate effect.
            </p>
            <p>
              No clarification or explanation of the Terms provided by the
              Parties will have the power to modify the provisions of these TOS.
            </p>
          </div>
        </section>
        <CustomSolution />
      </section>
    </React.Fragment>
  );
}
export default TermsOfService;
