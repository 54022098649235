import React from "react";
import CustomSolution from "../components/customSolution";
import PageTitle from "../components/pageTitle";
import UnlimitedNotice from "../components/unlimitednotice";

function WindowsWordPressWebHosting() {
  return (
    <React.Fragment>
      <PageTitle
        title={"Windows Wordpress Web Hosting | Hosting Gujju | Made in India"}
      />
      <section id="content">
        <section className="content-row content-row-clouds">
          <div className="container">
            <header className="content-header content-header-large content-header-uppercase">
              <h1>
                <mark>Wordpress</mark> Windows Web Hosting
              </h1>
              <p>
                Get high-speed and reliable virtual cloud servers especially
                optimized for WordPress websites. Leave all your worries about
                installation, updation, and management to us.,
                <br /> powered by{" "}
                <span className="text-color-secondary">
                  Plesk<sup>&reg;</sup>
                </span>{" "}
                control panel.
              </p>
            </header>
          </div>
        </section>
        <section id="pricing" className="content-row content-row-full-height">
          <div className="container">
            <div className="tab-group tab-group-switch-style">
              <div className="tab-item" data-title="3 Year">
                <div className="column-row align-center-bottom">
                  <div className="column-33">
                    <div className="product-box">
                      <div className="product-header">
                        <h4>Starter</h4>
                        <p>Buy &amp; Renew at same cost!</p>
                      </div>
                      <div className="product-price">
                        ₹40<span className="term">/ month</span>
                      </div>
                      <div className="product-features">
                        <ul>
                          <li>
                            <strong>1</strong> Hosted Domains
                          </li>
                          <li>
                            <strong>1 GB</strong> SSD Storage
                          </li>
                          <li>
                            <strong>1</strong> MySQL Database
                          </li>
                          <li>
                            <strong>1</strong> MSSQL Database
                          </li>
                          <li>
                            <strong>Unlimited</strong> Subdomains
                          </li>
                          <li>
                            <strong>Unlimited</strong> Bandwidth
                          </li>
                        </ul>
                        <ul>
                          <li>
                            Powered by Plesk<sup>&reg;</sup>
                          </li>
                          <li>Free SSL Certificate</li>
                          <li>WordPress Optimized</li>
                          <li>99.95% Uptime SLA</li>
                        </ul>
                      </div>
                      <div className="product-order">
                        <a
                          className="button button-secondary"
                          href="https://hostinggujju.com/clients/cart.php?a=add&pid=9"
                        >
                          <i className="fas fa-shopping-cart icon-left"></i>
                          Order Now
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="column-33">
                    <div className="product-box product-box-popular">
                      <div className="product-popular">Most Popular</div>
                      <div className="product-header">
                        <h4>Value</h4>
                        <p>Buy &amp; Renew at same cost!</p>
                      </div>
                      <div className="product-price">
                        ₹100<span className="term">/ month</span>
                      </div>
                      <div className="product-features">
                        <ul>
                          <li>
                            <strong>1</strong> Hosted Domains
                          </li>
                          <li>
                            <strong>Unlimited GB</strong> SSD Storage
                          </li>
                          <li>
                            <strong>Unlimited</strong> MySQL Database
                          </li>
                          <li>
                            <strong>Unlimited</strong> MSSQL Database
                          </li>
                          <li>
                            <strong>Unlimited</strong> Subdomains
                          </li>
                          <li>
                            <strong>Unlimited</strong> Bandwidth
                          </li>
                        </ul>
                        <ul>
                          <li>
                            Powered by Plesk<sup>&reg;</sup>
                          </li>
                          <li>Free SSL Certificate</li>

                          <li>WordPress Optimized</li>
                          <li>99.95% Uptime SLA</li>
                        </ul>
                      </div>
                      <div className="product-order">
                        <a
                          className="button button-secondary"
                          href="https://hostinggujju.com/clients/cart.php?a=add&pid=10"
                        >
                          <i className="fas fa-shopping-cart icon-left"></i>
                          Order Now
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="column-33">
                    <div className="product-box">
                      <div className="product-header">
                        <h4>Delux</h4>
                        <p>Buy &amp; Renew at same cost!</p>
                      </div>
                      <div className="product-price">
                        ₹150<span className="term">/ month</span>
                      </div>
                      <div className="product-features">
                        <ul>
                          <li>
                            <strong>Unlimited</strong> Hosted Domains
                          </li>
                          <li>
                            <strong>Unlimited GB</strong> SSD Storage
                          </li>
                          <li>
                            <strong>Unlimited</strong> MySQL Database
                          </li>
                          <li>
                            <strong>Unlimited</strong> MSSQL Database
                          </li>
                          <li>
                            <strong>Unlimited</strong> Subdomains
                          </li>
                          <li>
                            <strong>Unlimited</strong> Bandwidth
                          </li>
                        </ul>
                        <ul>
                          <li>
                            Powered by Plesk<sup>&reg;</sup>
                          </li>
                          <li>Free SSL Certificate</li>
                          <li>WordPress Optimized</li>
                          <li>99.95% Uptime SLA</li>
                        </ul>
                      </div>
                      <div className="product-order">
                        <a
                          className="button button-secondary"
                          href="https://hostinggujju.com/clients/cart.php?a=add&pid=11"
                        >
                          <i className="fas fa-shopping-cart icon-left"></i>
                          Order Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-item" data-title="1 Year">
                <div className="column-row align-center-bottom">
                  <div className="column-33">
                    <div className="product-box">
                      <div className="product-header">
                        <h4>Starter</h4>
                        <p>Buy &amp; Renew at same cost!</p>
                      </div>
                      <div className="product-price">
                        ₹60<span className="term">/ month</span>
                      </div>
                      <div className="product-features">
                        <ul>
                          <li>
                            <strong>1</strong> Hosted Domains
                          </li>
                          <li>
                            <strong>1 GB</strong> SSD Storage
                          </li>
                          <li>
                            <strong>1</strong> MySQL Database
                          </li>
                          <li>
                            <strong>1</strong> MSSQL Database
                          </li>
                          <li>
                            <strong>Unlimited</strong> Subdomains
                          </li>
                          <li>
                            <strong>Unlimited</strong> Bandwidth
                          </li>
                        </ul>
                        <ul>
                          <li>
                            Powered by Plesk<sup>&reg;</sup>
                          </li>
                          <li>Free SSL Certificate</li>

                          <li>WordPress Optimized</li>
                          <li>99.95% Uptime SLA</li>
                        </ul>
                      </div>
                      <div className="product-order">
                        <a
                          className="button button-secondary"
                          href="https://hostinggujju.com/clients/cart.php?a=add&pid=9"
                        >
                          <i className="fas fa-shopping-cart icon-left"></i>
                          Order Now
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="column-33">
                    <div className="product-box product-box-popular">
                      <div className="product-popular">Most Popular</div>
                      <div className="product-header">
                        <h4>Value</h4>
                        <p>Buy &amp; Renew at same cost!</p>
                      </div>
                      <div className="product-price">
                        ₹120<span className="term">/ month</span>
                      </div>
                      <div className="product-features">
                        <ul>
                          <li>
                            <strong>1</strong> Hosted Domains
                          </li>
                          <li>
                            <strong>Unlimited GB</strong> SSD Storage
                          </li>
                          <li>
                            <strong>Unlimited</strong> MySQL Database
                          </li>
                          <li>
                            <strong>Unlimited</strong> MSSQL Database
                          </li>
                          <li>
                            <strong>Unlimited</strong> Subdomains
                          </li>
                          <li>
                            <strong>Unlimited</strong> Bandwidth
                          </li>
                        </ul>
                        <ul>
                          <li>
                            Powered by Plesk<sup>&reg;</sup>
                          </li>
                          <li>Free SSL Certificate</li>
                          <li>WordPress Optimized</li>
                          <li>99.95% Uptime SLA</li>
                        </ul>
                      </div>
                      <div className="product-order">
                        <a
                          className="button button-secondary"
                          href="https://hostinggujju.com/clients/cart.php?a=add&pid=10"
                        >
                          <i className="fas fa-shopping-cart icon-left"></i>
                          Order Now
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="column-33">
                    <div className="product-box">
                      <div className="product-header">
                        <h4>Delux</h4>
                        <p>Buy &amp; Renew at same cost!</p>
                      </div>
                      <div className="product-price">
                        ₹170<span className="term">/ month</span>
                      </div>
                      <div className="product-features">
                        <ul>
                          <li>
                            <strong>Unlimited</strong> Hosted Domains
                          </li>
                          <li>
                            <strong>Unlimited GB</strong> SSD Storage
                          </li>
                          <li>
                            <strong>Unlimited</strong> MySQL Database
                          </li>
                          <li>
                            <strong>Unlimited</strong> MSSQL Database
                          </li>
                          <li>
                            <strong>Unlimited</strong> Subdomains
                          </li>
                          <li>
                            <strong>Unlimited</strong> Bandwidth
                          </li>
                        </ul>
                        <ul>
                          <li>
                            Powered by Plesk<sup>&reg;</sup>
                          </li>
                          <li>Free SSL Certificate</li>
                          <li>WordPress Optimized</li>
                          <li>99.95% Uptime SLA</li>
                        </ul>
                      </div>
                      <div className="product-order">
                        <a
                          className="button button-secondary"
                          href="https://hostinggujju.com/clients/cart.php?a=add&pid=11"
                        >
                          <i className="fas fa-shopping-cart icon-left"></i>
                          Order Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-item" data-title="Monthly">
                <div className="column-row align-center-bottom">
                  <div className="column-33">
                    <div className="product-box">
                      <div className="product-header">
                        <h4>Starter</h4>
                        <p>Buy &amp; Renew at same cost!</p>
                      </div>
                      <div className="product-price">
                        ₹100<span className="term">/ month</span>
                      </div>
                      <div className="product-features">
                        <ul>
                          <li>
                            <strong>1</strong> Hosted Domains
                          </li>
                          <li>
                            <strong>1 GB</strong> SSD Storage
                          </li>
                          <li>
                            <strong>1</strong> MySQL Database
                          </li>
                          <li>
                            <strong>1</strong> MSSQL Database
                          </li>
                          <li>
                            <strong>Unlimited</strong> Subdomains
                          </li>
                          <li>
                            <strong>Unlimited</strong> Bandwidth
                          </li>
                        </ul>
                        <ul>
                          <li>
                            Powered by Plesk<sup>&reg;</sup>
                          </li>
                          <li>Free SSL Certificate</li>

                          <li>WordPress Optimized</li>
                          <li>99.95% Uptime SLA</li>
                        </ul>
                      </div>
                      <div className="product-order">
                        <a
                          className="button button-secondary"
                          href="https://hostinggujju.com/clients/cart.php?a=add&pid=9"
                        >
                          <i className="fas fa-shopping-cart icon-left"></i>
                          Order Now
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="column-33">
                    <div className="product-box product-box-popular">
                      <div className="product-popular">Most Popular</div>
                      <div className="product-header">
                        <h4>Value</h4>
                        <p>Buy &amp; Renew at same cost!</p>
                      </div>
                      <div className="product-price">
                        ₹150<span className="term">/ month</span>
                      </div>
                      <div className="product-features">
                        <ul>
                          <li>
                            <strong>1</strong> Hosted Domains
                          </li>
                          <li>
                            <strong>Unlimited GB</strong> SSD Storage
                          </li>
                          <li>
                            <strong>Unlimited</strong> MySQL Database
                          </li>
                          <li>
                            <strong>Unlimited</strong> MSSQL Database
                          </li>
                          <li>
                            <strong>Unlimited</strong> Subdomains
                          </li>
                          <li>
                            <strong>Unlimited</strong> Bandwidth
                          </li>
                        </ul>
                        <ul>
                          <li>
                            Powered by Plesk<sup>&reg;</sup>
                          </li>
                          <li>Free SSL Certificate</li>

                          <li>WordPress Optimized</li>
                          <li>99.95% Uptime SLA</li>
                        </ul>
                      </div>
                      <div className="product-order">
                        <a
                          className="button button-secondary"
                          href="https://hostinggujju.com/clients/cart.php?a=add&pid=10"
                        >
                          <i className="fas fa-shopping-cart icon-left"></i>
                          Order Now
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="column-33">
                    <div className="product-box">
                      <div className="product-header">
                        <h4>Delux</h4>
                        <p>Buy &amp; Renew at same cost!</p>
                      </div>
                      <div className="product-price">
                        ₹200<span className="term">/ month</span>
                      </div>
                      <div className="product-features">
                        <ul>
                          <li>
                            <strong>Unlimited</strong> Hosted Domains
                          </li>
                          <li>
                            <strong>Unlimited GB</strong> SSD Storage
                          </li>
                          <li>
                            <strong>Unlimited</strong> MySQL Database
                          </li>
                          <li>
                            <strong>Unlimited</strong> MSSQL Database
                          </li>
                          <li>
                            <strong>Unlimited</strong> Subdomains
                          </li>
                          <li>
                            <strong>Unlimited</strong> Bandwidth
                          </li>
                        </ul>
                        <ul>
                          <li>
                            Powered by Plesk<sup>&reg;</sup>
                          </li>
                          <li>Free SSL Certificate</li>

                          <li>WordPress Optimized</li>
                          <li>99.95% Uptime SLA</li>
                        </ul>
                      </div>
                      <div className="product-order">
                        <a
                          className="button button-secondary"
                          href="https://hostinggujju.com/clients/cart.php?a=add&pid=11"
                        >
                          <i className="fas fa-shopping-cart icon-left"></i>
                          Order Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-row content-row-gray">
          <div className="container">
            <header className="content-header">
              <h2>Shared Hosting Features</h2>
              <p>
                Don't know which shared hosting plan to choose? Our extended
                feature comparison should help you decide which product you
                require for your application environment.
              </p>
            </header>
            <table className="table-layout-product-comparison">
              <thead>
                <tr>
                  <th></th>
                  <th>Starter</th>
                  <th>Value</th>
                  <th>Delux</th>
                </tr>
              </thead>
              <tbody>
                <tr className="product-header">
                  <td></td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Starter</span>
                    <h4>Starter</h4>
                    <p>Host a single website.</p>
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Value</span>
                    <h4>Value</h4>
                    <p>Basic resources for starter sites.</p>
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Delux</span>
                    <h4>Delux</h4>
                    <p>More space and flexibility for multiple sites.</p>
                  </td>
                </tr>
                <tr>
                  <td>Hosted Domains</td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Starter</span>1
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Value</span>1
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Delux</span>Unlimited
                  </td>
                </tr>
                <tr>
                  <td>SSD Storage</td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Starter</span>1 GB
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Value</span>Unlimited GB
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Delux</span>Unlimited GB
                  </td>
                </tr>
                <tr>
                  <td>Bandwidth</td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Starter</span>Unlimited GB
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Value</span>Unlimited GB
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Delux</span>Unlimited GB
                  </td>
                </tr>
                <tr>
                  <td>Plesk Control Panel</td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Starter</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Value</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Delux</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                </tr>
                <tr>
                  <td>Sub-Domains</td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Starter</span>
                    Unlimited
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Value</span>
                    Unlimited
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Delux</span>
                    Unlimited
                  </td>
                </tr>
                <tr>
                  <td>SSL Certificate</td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Starter</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Value</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Delux</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                </tr>
                <tr>
                  <td>FTP Users</td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Starter</span>1
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Value</span>
                    Unlimited
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Delux</span>
                    Unlimited
                  </td>
                </tr>
                <tr>
                  <td>Web Disk</td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Starter</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Value</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Delux</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                </tr>
                <tr>
                  <td>Customizable php.ini</td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Starter</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Value</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Delux</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                </tr>
                <tr>
                  <td>Webmail</td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Starter</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Value</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Delux</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                </tr>
                <tr>
                  <td>99.95% Uptime Guarantee</td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Starter</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Value</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Delux</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                </tr>
                <tr>
                  <td>Instant Setup</td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Starter</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Value</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Delux</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                </tr>
                <tr>
                  <td>Many More...</td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Starter</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Value</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Delux</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                </tr>
                <tr>
                  <td>Ticket Support</td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Starter</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Value</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Delux</span>
                    <i className="fas fa-check text-color-primary"></i>
                  </td>
                </tr>
                <tr className="product-feature-group">
                  <td>Pricing Options</td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Starter</span>
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Value</span>
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Delux</span>
                  </td>
                </tr>
                <tr>
                  <td>Monthly</td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Starter</span>₹100
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Value</span>₹150
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Delux</span>₹200
                  </td>
                </tr>
                <tr>
                  <td>1 Year</td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Starter</span>₹720
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Value</span>₹1,440
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Delux</span>₹2,040
                  </td>
                </tr>
                <tr>
                  <td>3 Year</td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Starter</span>₹1,440
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Value</span>₹3,600
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Delux</span>₹5,400
                  </td>
                </tr>
                <tr className="product-order">
                  <td></td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Starter</span>
                    <a
                      className="button button-secondary"
                      href="https://hostinggujju.com/clients/cart.php?a=add&pid=9"
                    >
                      <i className="fas fa-shopping-cart icon-left"></i>Order
                      Now
                    </a>
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Value</span>
                    <a
                      className="button button-secondary"
                      href="https://hostinggujju.com/clients/cart.php?a=add&pid=10"
                    >
                      <i className="fas fa-shopping-cart icon-left"></i>Order
                      Now
                    </a>
                  </td>
                  <td className="has-responsive-th">
                    <span className="responsive-th">Delux</span>
                    <a
                      className="button button-secondary"
                      href="https://hostinggujju.com/clients/cart.php?a=add&pid=11"
                    >
                      <i className="fas fa-shopping-cart icon-left"></i>Order
                      Now
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <UnlimitedNotice />
          </div>
        </section>
        <section className="content-row">
          <div className="container">
            <header className="content-header">
              <h2>Enterprise Cloud Platform</h2>
              <p>
                Deploy your service infrastructure on our fully redundant, high
                performance cloud platform and benefit from its high
                reliability, security and enterprise feature set.
              </p>
            </header>
            <div className="column-row align-center-bottom text-align-center">
              <div className="column-33">
                <i className="fas fa-rocket icon-feature"></i>
                <h3>High Performance</h3>
                <p>
                  We operate one of the most advanced 100 Gbit networks in the
                  world, complete with Anycast support and extensive DDoS
                  protection.
                </p>
              </div>
              <div className="column-33">
                <i className="fas fa-cloud icon-feature"></i>
                <h3>Fully Redundant</h3>
                <p>
                  Our cloud platform offers a 99.99% SLA uptime guarantee with
                  full hardware and network redundancy to keep your services
                  online.
                </p>
              </div>
              <div className="column-33">
                <i className="fas fa-shield-alt icon-feature"></i>
                <h3>Secure Infrastructure</h3>
                <p>
                  All datacenters are Tier 4 certified and provide advanced fire
                  and intrusion protection combined with enterprise networking
                  hardware.
                </p>
              </div>
            </div>
            <div className="column-row align-center-bottom text-align-center">
              <div className="column-33">
                <i className="fas fa-sync-alt icon-feature"></i>
                <h3>Instant Activation</h3>
                <p>
                  Your ordered product will be automatically and instantly
                  activated as long as you are using a verified PayPal account
                  for your purchase.
                </p>
              </div>
              <div className="column-33">
                <i className="fas fa-life-ring icon-feature"></i>
                <h3>Dedicated Support</h3>
                <p>
                  Our dedicated in-house support team is available 24/7 to
                  answer all technical difficulties you may encounter with any
                  of our products.
                </p>
              </div>
              <div className="column-33">
                <i className="fas fa-cog icon-feature"></i>
                <h3>Powerful Automation</h3>
                <p>
                  Our simple and intuitive developer API allows you to manage
                  and extend existing products, as well as submitting new orders
                  without interaction.
                </p>
              </div>
            </div>
          </div>
        </section>
        <CustomSolution />
      </section>
    </React.Fragment>
  );
}
export default WindowsWordPressWebHosting;
